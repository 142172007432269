import React, { Component, useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  TabContent,
  TabPane,
  Label,
  Button,
  NavItem,
  NavLink,
  Form,
  Spinner,
  Modal,
  ModalBody,
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  FormFeedback,

} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";

import classnames from "classnames"
import ReactApexChart from "react-apexcharts"
import { useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import Success from "../Components/Success";
import LineChart from "pages/Components/LineChart";


//Simple bar
import SimpleBar from "simplebar-react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Images
import avatar from "../../assets/images/users/avatar-1.jpg"
import { ConstructionOutlined } from "@mui/icons-material";
import axios from "axios";


const authEndpoint = process.env.REACT_APP_AUTHENDPOINT;

const Transfers = ({ currentUser }) => {


  const [days, setDays] = useState([]);
  const[modalOtp,setModalOtp] = useState(false)
  const [loadVerify, setLoadVerify] = useState(false);
  const [transactionId, setTransactionId] = useState(false);
  const [modalTransaction, setTransaction] = useState(false);
  const [transactionMessage, setTransactionMessage] = useState("");
  const [transaction, setTransactionHistory] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);

  

  // const [transactionFail, setTransactionMessage] = useState(false);
  // const [modalFailedPassword, setModalFailedPassword] = useState(false);
  // const [modalFailedEmail, setModalFailedEmail] = useState(false);

  

  



  

  const label = "transfer"
  const graphTitle = "outflow"

  // console.log(day)
  const amount = [1000, 5000, 3000, 2940, 2970, 3400];

  const getTransaction = ()=>{
    axios({
          url: `${authEndpoint}transaction/getSuccessTransaction/`,
          method: "get",
          headers: {
            Authorization: `JWT ${currentUser.token}`,
          },
        })
          .then((response) => {
            
            console.log(response)
    
            if (response && response.status === 200) {
              setTransactionHistory(response.data)
            }
    
    
          })
          .catch((error) => {
          
    
            console.log(error)
            console.log(error.response)
          //   if (error && error.response && error.response.status === 400) {
          //     console.log("otp verify failed");
          //     // setVerifyMessage(error.response.data.message[0])
          //     // setverifyFail(true)
          //   }
           });

  }

   const confirmTransfer = (values)=>{
     console.log(values)
    const fd ={
      transfer_id : transactionId,
      token : values.inputOtp,
    }
    console.log(fd)

    axios({
      url: `${authEndpoint}transaction/verifyTransaction/`,
      method: "post",
      data: fd,
      headers: {
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then((response) => {
       
        console.log(response)

        if (response && response.status === 200) {

          setModalOtp(false);
          setTransactionMessage("Transfer Successfull")
          getTransaction();

          setTransaction(true)
        }


      })
      .catch((error) => {
        setLoadVerify(false);
        console.log(error)
        console.log(error.response)
        setTransactionMessage(error && error.response && error.response.data && error.response.data.message)
        setTransaction(true)
        

     
      });
    
   }

  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      inputOtp: '',
    },
    validationSchema: Yup.object({
      inputOtp: Yup.string().required("Please Enter Your Otp"),
    }),
    onSubmit: (values) => {
      console.log(values)

      confirmTransfer(values);
    //  setModalOtp(true)
    //   verifyOTP(values)
    }
  });

  // const verifyOTP = (values) => {
  //   setLoadVerify(true)
  //   console.log(values)
  //   const fd = {
  //     otp: values.inputOtp
  //   }
  //   console.log(fd)
  //   axios({
  //     url: `${authEndpoint}users/verifyOtp/`,
  //     method: "post",
  //     data: fd,
  //     headers: {
  //       Authorization: `JWT ${currentUser.token}`,
  //     },
  //   })
  //     .then((response) => {
  //       setLoadVerify(false);
  //       setModalOtp(false)
  //       console.log(response)

  //       if (response && response.status === 200) {
  //         console.log("verifyotp success");
  //         // setVerifySuccess(true)

  //       }


  //     })
  //     .catch((error) => {
  //       setLoadVerify(false);
  //       setModalOtp(false)

  //       console.log(error)
  //       console.log(error.response)
  //       if (error && error.response && error.response.status === 400) {
  //         console.log("otp verify failed");
  //         // setVerifyMessage(error.response.data.message[0])
  //         // setverifyFail(true)
  //       }
  //     });
  // }


  

  useEffect(() => {
    let da = [
      "06-01-2022", "06-02-2022", "06-07-2022", "07-06-2022", "07-05-2022", "06-06-2022", "06-07-2022", "08-08-2022", "08-09-2022", "06-10-2022"
    ]

    let day = da.map((item) => {

      // console.log(new Date(item).toDateString())
      return new Date(item).toDateString()
    })
    setDays(day)

  }, [])


  const getWalletBalance = () =>{

    axios({
      url: `${authEndpoint}transaction/getTransactionWallet/`,
      method: "get",
      headers: {
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then((response) => {
       
        console.log(response)

        if (response && response.status === 200) {

          setWalletBalance(response && response.data && response.data.balance)
        }


      })
      .catch((error) => {
        // setLoadVerify(false);
        console.log(error)
        console.log(error.response)
        // setTransactionMessage(error && error.response && error.response.data && error.response.data.message)
        // setTransaction(true)
        

     
      });

  }


  useEffect(() => {
  

 getTransaction();
 getWalletBalance();

  }, [])

  const getOneday = () => {

    let one = days.filter((item) => {
      console.log(new Date(item).getDate())
      console.log(new Date().getDate())
      if (new Date().getMonth() === new Date(item).getMonth()) {
       return new Date().getDate() - new Date(item).getDate() >= 7;
      }
    })
    console.log(one)
    setDays(one)

  }


  const options = {
    chart: { toolbar: !1, zoom: { enabled: !0 } },
    plotOptions: {
      candlestick: { colors: { upward: "#34c38f", downward: "#f46a6a" } },
    },
    xaxis: { type: "datetime" },
    yaxis: { tooltip: { enabled: !0 } },
  }

  const notofications = [
    {
      date: "15 May",
      desc: "Your Transfer of ₦5,000 to Miriam have been process successfully",
    },
    {
      date: "14 May",
      desc: "You recieved ₦5000 from Opral Ogbuigwe.Your wallet Balance is #20000",
    },

    {
      date: "15 May",
      desc: "Your Transfer of ₦5,000 to Miriam have been process successfully",
    },
    {
      date: "14 May",
      desc: "You recieved ₦5000 from Opral Ogbuigwe.Your wallet Balance is ₦20000",
    },
    {
      date: "10 Mar",
      desc: "It will seem like simplified English as a skeptical Cambridge.",
    },
    { date: "9 Mar", desc: "To achieve this, it would be necessary." },
  ]

  const [isMenu, setisMenu] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleMenu = () => {
    setisMenu(!isMenu)
  }


  const sendConfirmOtp = (values)=>{
    const fd ={
      email : values.email,
      amount : values.amount,
    }
    console.log(fd)

    axios({
      url: `${authEndpoint}transaction/sendConfirmOtp/`,
      method: "post",
      data: fd,
      headers: {
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then((response) => {
       
        console.log(response)

        if (response && response.status === 200) {

          setTransactionId(response.id)
         setModalOtp(true);

        }


      })
      .catch((error) => {
        setLoadVerify(false);
        setTransactionMessage("couldn't send Otp")
        setTransaction(true)
        setModalOtp(true);


     
      });
  }

  const confirmPassword = (values)=>{

    const fd ={
      password : values.password
    }
    console.log(fd)

    axios({
      url: `${authEndpoint}transaction/confirmpassword/`,
      method: "post",
      data: fd,
      headers: {
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then((response) => {
       
        console.log(response)

        if (response && response.status === 200) {
         sendConfirmOtp(values);

        }


      })
      .catch((error) => {
        setLoadVerify(false);
        // setModalFailedPassword(true)

        setTransactionMessage("incorrect agent pasword")
        setTransaction(true)
        sendConfirmOtp(values);


     
      });

  }

  const confirmEmail = (values) =>{
    
    const fd ={
      email : values.email,
      password : values.password,
      amount : values.amount,
    }
    console.log(fd)

    axios({
      url: `${authEndpoint}transaction/transfer/`,
      method: "post",
      data: fd,
      headers: {
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then((response) => {
       
        console.log(response)

        if (response && response.status === 200) {
          console.log(response.data.id)
          setTransactionId(response.data. id)
          setModalOtp(true);

        }


      })
      .catch((error) => {
        console.log(error)
        console.log(error.response)
        setLoadVerify(false);
        // setModalFailedEmail(true)
        setTransactionMessage(error && error.response && error.response.data && error.response.data.message)
        setTransaction(true)
        // confirmPassword(values);
        

     
      });

  }

 const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      amount: '',
      email: '',
      // phonenumber: '',
      password: '',
    },
    validationSchema: Yup.object({
      amount: Yup.string().required("Please Enter Your Amount"),
      email: Yup.string().required("Please Enter Your Reciever's Email"),
      // phonenumber: Yup.number().required("Please Enter Your Reciever's Phonenumber"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      confirmEmail(values);
      console.log(values)
      console.log(history)

    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> dashboard  herconomy - Agency</title>
        </MetaTags>
        <Container fluid>
          <h4 className="mb-0 font-size-18">Transfer</h4>
          <Row>
            <Col xl="8">
              <Card>
                <CardBody className="transfercard">
                  <h4 className="card-title mb-4">Rate of Transfer</h4>

                  <Row >
                    <Col xl="12" sm="12" >
                      <div className="mt-4 mt-sm-0">
                        <p className="text-dark ">outflow</p>
                        <h5>₦5000</h5>
                      </div>
                      <div className="d-flex gap-2 float-end transferbtn">
                        <Button className="btn btn-secondary btn-sm  mb-3"
                          style={{ backgroundColor: "#000000" }}

                        >
                          Last 24 hrs
                        </Button>

                        <Button className="btn btn-secondary btn-sm  mb-3"
                          style={{ backgroundColor: "#000000" }}

                        >
                          7 days
                        </Button>
                        <Button className="btn btn-primary btn-sm mb-3"
                          style={{ backgroundColor: "#000000" }}

                        >
                          30 days
                        </Button>
                        <Button className="btn-primary btn-sm  mb-3"
                          style={{ backgroundColor: "#000000" }}
                        >
                          6 Months
                        </Button>
                        <Button className="btn-primary  btn-sm mb-3"
                          style={{ backgroundColor: "#000000" }}
                        >
                          1 Year
                        </Button>
                      </div>

                      {/* <div className="toolbar d-flex flex-wrap gap-2 text-end">
                  <button type="button" className="btn btn-light btn-sm">
                    ALL
                  </button>{" "}
                  <button type="button" className="btn btn-light btn-sm">
                    1M
                  </button>{" "}
                  <button type="button" className="btn btn-light btn-sm">
                    6M
                  </button>{" "}
                  <button type="button" className="btn btn-light btn-sm active">
                    1Y
                  </button>{" "}
                </div> */}


                    </Col>

                  </Row>

                  <div className="mt-4">
                    {/* <div id="candlestick-chart" dir="ltr"> */}
                      {/* <ReactApexChart
                        series={series}
                        options={options}
                        type="candlestick"
                        height={310}
                        className="apex-charts"
                      /> */}

                      <LineChart
                        labelNames={days}
                        dataValues={amount}
                        dataLabel={label}
                        graphTitle={graphTitle}
                      />
                    {/* </div> */}
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* <Col xl="4">
              <Card>
                <CardBody className="transfercard">
                  <h4 className="card-title mb-4">Transfers</h4>

                  <div>
                    <p className="text-muted mb-2">
                      <i className="mdi mdi-wallet me-1" /> Wallet Balance
                    </p>
                    <h5>&#8358;{walletBalance}</h5>
                  </div>

                  <div className="mt-4 ">
                    <Nav pills className="d-flex justify-content-center align-items-center bg-warning rounded text-center" role="tablist">
                      <NavItem>
                        <NavLink
                          className="text-center"
                          //   active: activeTab === "1",
                          // })}
                          onClick={() => {
                            toggleTab("1")
                          }}
                        >
                          Make Transfer
                        </NavLink>
                      </NavItem>

                    </Nav>

                    <TabContent activeTab={activeTab} className="mt-4">
                      <TabPane tabId="1" id="buy-tab">

                        <div>
                          <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <div>


                              <InputGroup className="mb-3">
                                <Label className="input-group-text hercolor">Amount &#8358;</Label>
                                <Input name="amount" type="number" className="form-control" onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  // value={validation.values.amount || ""}
                                  invalid={
                                    validation.touched.amount && validation.errors.amount ? true : false
                                  }
                                />
                                {validation.touched.amount && validation.errors.amount ? (
                                  <FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
                                ) : null}
                              </InputGroup>

                              <InputGroup className="mb-3">
                                <Label className="input-group-text hercolor">Email</Label>
                                <Input name="email" type="email" className="form-control" placeholder="Receiver's Email" onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  // value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                  }
                                />
                                {validation.touched.email && validation.errors.email ? (
                                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                ) : null}
                              </InputGroup>

                              <InputGroup className="mb-3">
                                <Label className="input-group-text hercolor">Password</Label>
                                <Input name="password" type="password" className="form-control" onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  // value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.password && validation.errors.password ? true : false
                                  }
                                />
                                {validation.touched.password && validation.errors.password ? (
                                  <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                ) : null}                            </InputGroup>

                            </div>

                            <div className="d-flex  justify-content-center align-items-center">
                              <Button
                                type="submit"
                                 color="warning"
                                className="w-md font-weight-semibold text-dark  float-start"
                                // onClick={() => { setModalOtp(true) }}
                              >
                                Transfer
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </TabPane>

                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>

          <Row>
            <Col xl="6">
              <Card>
                <CardBody className="transfercard1">
                  <h4 className="card-title mb-4">Transfer History</h4>

                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>

                        <tr>
                          <th scope="col">DATE</th>
                          <th scope="col">REFERENCE ID</th>
                          <th scope="col">AMOUNT</th>
                          <th scope="col">ACCOUNT NAME</th>
                          <th scope="col">EMAIL</th>
                        </tr>
                      </thead>
                      <tbody>

                        {transaction.map((transaction, key) => (
                          <tr key={key}>
                            <td>{transaction.date}</td>
                            <td>{transaction.transaction_id}</td>
                            <td>{transaction.amount}</td>
                            <td>{transaction.user_account_name}</td>
                            <td>{transaction.user_email}</td>
                            <td></td>

                          </tr>))}

                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardBody className="transfercard1">
                  <h4 className="card-title mb-4">Recent Transfers</h4>

                  <SimpleBar style={{ maxHeight: "310px" }}>
                    <ul className="verti-timeline list-unstyled">
                      {notofications.map((notification, key) => (
                        <li key={key} className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle font-size-18" />
                          </div>
                          <div className="d-flex">
                            <div className="me-3">
                              <h5 className="font-size-14">
                                {notification.date}{" "}
                                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                              </h5>
                            </div>
                            <div className="flex-grow-1">
                              <div>{notification.desc}</div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </SimpleBar>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modalTransaction}
            toggle={() => setTransaction(!modalOtp)}
            style={{ borderRadius: '20px' }}
            className="complaince-modal"
          >
            <ModalBody>
              <Row className="message-cont">

               {/* <FaUserTie className="compliance mb-5" /> */}

                <p className=" h5  font-weight-medium text-center para-head-center mb-3">
                    {transactionMessage}
                </p>


 <div className="d-flex justify-content-center align-items-center">   


                                  <Button
                                    type="button"
                                    color="warning"
                                    className="text-dark "
                                     onClick={()=>{
                                        setTransaction(false)                                  }
                                     }
                                  >
                               OK
                                                                </Button>
                                  </div>
                </Row>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={modalOtp}
            toggle={() => setModalOtp(!modalOtp)}
            style={{ borderRadius: '20px' }}
            className="complaince-modal"
          >
            <ModalBody>
              <Row className="message-cont">

               {/* <FaUserTie className="compliance mb-5" /> */}

                <p className=" h5  font-weight-medium text-center para-head-center mb-3">
                Please Input OTP sent to your phone number
                </p>

                <Form
                              className="form-horizontal"
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation1.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col lg="12">
                                  <FormGroup className="mb-3">
                                    <Label className="form-label">Input Otp</Label>

                                    <Input
                                      name="inputOtp"
                                      //   placeholder="First name"
                                      type="text"
                                      className="form-control"
                                      onChange={validation1.handleChange}
                                      onBlur={validation1.handleBlur}
                                      value={validation1.values.inputOtp || ""}
                                      invalid={
                                        validation1.touched.inputOtp && validation1.errors.inputOtp ? true : false
                                      }
                                    />
                                    {validation1.touched.inputOtp && validation1.errors.inputOtp ? (
                                      <FormFeedback type="invalid">{validation1.errors.inputOtp}</FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>


                              <div className=" d-flex justify-content-center align-items-center ">


                              <Button
                                    type="button"
                                    color="warning"
                                    className="btn btn-warning text-dark btn-block font-weight-semibold me-2 "
                                    onClick={()=>{
                                      setModalOtp(false)                                  }
                                     }
                                  >
                                    {/* <i className="mdi mdi-plus me-1" /> */}
                               Close
                                                                </Button>


                                <button
                                  className="btn btn-warning text-dark btn-block font-weight-semibold "
                                  type="submit"
                                  color="warning"

                                >
                                  {loadVerify ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    'Confirm Transfer'
                                  )}
                                </button>
                              </div>
                            </Form>

  {/* <div className="d-flex justify-content-center align-items-center">   


                                  <Button
                                    type="button"
                                    color="warning"
                                    className="btn-rounded text-dark mb-2 me-2"
                                     onClick={()=>{
                                        setModalOtp(false)                                  }
                                     }
                                  >
                               OK
                                                                </Button>
                                  </div> */}


              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      {/* {currentUser.onboarded === false && 

<Success />
        } */}
    </React.Fragment>
  );
};

Transfers.propTypes = {
  currentUser: PropTypes.object,


};

const mapStateToProps = (state) => {
  const { currentUser } = state.Login;
  console.log(state)
  return { currentUser };
};

export default connect(mapStateToProps, {
})(Transfers)
