import React, { Component, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, CardTitle, CardFooter, Container, Modal, ModalBody, Button } from "reactstrap";
import { useSelector, connect } from "react-redux";
import WelcomeCard from "./Components/WelcomeCard";
import SummaryCard from "../Components/SummaryCard";
import AdminList from "../Components/AdminList";
import Success from "../Components/Success";
import ModalPop from "../Components/ModalPop";
import ComplianceOtp from "../Components/InputOtp";
import ComparisonChart from "../Components/ComparisonChart";


import ReactApexChart from "react-apexcharts"
import SimpleBar from "simplebar-react"



import PropTypes from "prop-types";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Link, useHistory } from "react-router-dom"
import useFloat from "components/hooks/useFloat";
import Report from "pages/Components/Report";




import avatar1 from "../../assets/images/users/avatar-1 2.jpg";
import GroupIcon from "@mui/icons-material/Group";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import BlockIcon from "@mui/icons-material/Block";
import FlagIcon from "@mui/icons-material/Flag";
import { FaSleigh, FaUserTie } from "react-icons/fa"
import { ReactComponent as CopyIcon } from '../../assets/images/logos/Vectorcopy.svg';
import { ReactComponent as WelcomeIcon } from '../../assets/images/logos/welcome.svg';
import { ReactComponent as Pendingpayouts } from '../../assets/images/logos/pendingpayouts.svg';
import { ReactComponent as Commisions } from '../../assets/images/logos/commissions.svg';
import { ReactComponent as TotalRefer } from '../../assets/images/logos/totalrefer.svg';
import { ReactComponent as ReferPayout } from '../../assets/images/logos/referpayout.svg';
import { ReactComponent as CopyLinkIcon } from '../../assets/images/logos/copy_link.svg';



import { BsArrowUp } from "react-icons/bs";
import axios from "axios";
import LineChart from "pages/Components/LineChart";

import profileBg from "../../assets/images/welcomepic.png"


const authEndpoint = process.env.REACT_APP_AUTHENDPOINT;





const Dashboard = ({ currentUser }) => {

  const [modalError, setModalError] = useState(true);
  const [modalFundAccount, setModalFundAccount] = useState(false);
  const [userCount, setTotalUserCount] = useState(0)
  const [pendingPayouts, setpendingPayouts] = useState(0)
  const [referPayouts, setReferPayouts] = useState(0)
  const [commissions, setCommission] = useState(0)
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalPending, setTotalPending] = useState(0);



  const [referral_link, setReferralLink] = useState("")
  const [showCopiedTooltip, setShowCopiedToolTip] = useState(false)
  const [totalReferrals, setTotalReferrals] = useState(4500);
  const [referlists, setReferralList] = useState([]);
  const [showCopiedTooltipLink, setShowCopiedToolTipLink] = useState(false);


  // const [walletBalance, setWalletBalance] = useState(0);


  const history = useHistory();

  console.log(totalPaid)
  console.log(totalPending)


  const testdata = [
    {
      label: "Plans Created",
      data: [19, 29, 33, 14, 22, 31, 34, 24, 30, 15, 21, 39],
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    }
  ];


  // const [days, setDays] = useState([]);

  const label = "transfer"
  const graphTitle = ""

  // console.log(day)
  const amount = [10, 100, 200, 50, 300, 400];
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // useEffect(() => {
  //   let da = [
  //     "08-02-2022", "09-07-2022", "10-06-2022", "11-05-2022", "12-06-2022"
  //   ]

  //   let day = da.map((item) => {

  //     // console.log(new Date(item).toDateString())
  //     let eachmonth = monthNames[new Date(item).getMonth()];
  //     console.log(eachmonth);
  //     let eachmon = eachmonth.toString().slice(0, 3);
  //     console.log(eachmon);

  //     return eachmon;
  //   })
    // setDays(day)

  // }, [])






  const {
    getUserFloat,
    getUserTransactions,
    floatBalance,
    FloatAccount,
    allTransactions,
    FloatBank,
    generateBankAccount,
    verifyPhoneModal,
    setVerifyPhoneModal,
    loading,
    failureModal,
    setFailureModal,
    errorMessage,
  } = useFloat({
    currentUser: currentUser,
  });



  // const d = new Date();
  // const thisYear = new Date().getFullYear();
  // console.log(currentUser)

  // const thisMonth = monthNames[d.getMonth()];
  // console.log(thisMonth)


  console.log(currentUser)

  const getAdmins = () => {

  }


  const columns = [{
    dataField: 'id',
    text: 'S/No',
    sort: true,
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true
  }, {
    dataField: 'phonenumber',
    text: 'Phone Number',
    sort: true
  },
  {
    dataField: 'referralBonus',
    text: 'Referral Bonus',
    sort: true
  },
  {
    dataField: 'created_at',
    text: 'Date Created',
    sort: true
  }
  ];


  const productData2 = [[1, "Miriam Dike", "tochi@herconomy.com", "08083844955", "yes", "Daily"], [1, "Miriam Dike", "tochi@herconomy.com", "08083844955", "yes", "Daily"]]


  // Table Data
  const productData4 = [
    { "id": 1, "name": "Miriam Dike", "email": "willie.jennings@example.com", "phone-number": "20000", "registration-bonus": "Pending", "activation-bonus": "Pending" },

    { "id": 2, "name": "Miriam Dike", "email": "felicia.reid@example.com", "phone-number": "20000", "registration-bonus": "Pending", "activation-bonus": "Paid" },

    { "id": 3, "name": "Miriam Dike", "email": "tim.jennings@example.com", "phone-number": "20000", "registration-bonus": "Pending", "activation-bonus": "Pending" },

    { "id": 4, "name": "Miriam Dike", "email": "nathan.roberts@example.com", "phone-number": "20000", "registration-bonus": "Pending", "activation-bonus": "Paid" },

    { "id": 5, "name": "Miriam Dike", "email": "bill.sanders@example.com", "phone-number": "20000", "registration-bonus": "Pending", "activation-bonus": "Paid" },

    { "id": 6, "name": "Miriam Dike", "email": "jessica.hanson@example.com", "phone-number": "20000", "registration-bonus": "Pending", "activation-bonus": "pending" },
  ];

  // {referlists && referlists.result && referlists.result.length > 0 && referlists.result.map((item,index) => (

  //   {
  //     id: index,
  //     name: {item.to_user.first_name}{}

  //   }
  // )} 

  const productData = referlists && referlists.result && referlists.result.length > 0 && referlists.result.map((item, index) => {
    console.log(item)
    const paid = item.number_of_transactions === 1 || item.number_of_transactions === 2
    const pending = item.number_of_transactions === 2
    console.log(paid)
    console.log(pending)
    const time = new Date(item.created_at).toISOString().split('T')[0]

    return {
      id: index + 1,
      name: item.to_user && item.to_user?.first_name + " " + item.to_user?.last_name,
      email:item.to_user &&  item.to_user?.email,
      phonenumber: item.to_user && item.to_user?.phone_number,
      referralBonus: paid ? "Paid" : "Pending",
      created_at: time,



    }
  })
  console.log(productData)






  // const data1 = productData.map((item) => {
  //   console.log(item)

  //   return [item.id, item.name, item.position, item.office, item.age, item.startdate, item.salary]


  // }
  // ); //and map all the data you require.
  // console.log(data1)

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: referlists.length === 0 ? 0 : productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: referlists.length === 0 ? 0 : (productData).length }];




  const options = {
    chart: { toolbar: !1, zoom: { enabled: !0 } },
    plotOptions: {
      candlestick: { colors: { upward: "#34c38f", downward: "#f46a6a" } },
    },
    xaxis: { type: "datetime" },
    yaxis: { tooltip: { enabled: !0 } },
  }

  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }


  const getCommissionBalance = () => {

    axios({
      url: `${authEndpoint}users/getCommissionWallet/?timestamp=${new Date().getTime()}`,
      method: "get",
      headers: {
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then((response) => {

        console.log(response)

        if (response && response.status === 200) {

          setTotalReferrals(response && response.data && response.data.balance)
        }


      })
      .catch((error) => {
        // setLoadVerify(false);
        console.log(error)
        console.log(error.response)
        // setTransactionMessage(error && error.response && error.response.data && error.response.data.message)
        // setTransaction(true)



      });

  }

  // const getWalletBalance = () =>{

  //   axios({
  //     url: `${authEndpoint}transaction/getTransactionWallet/`,
  //     method: "get",
  //     headers: {
  //       Authorization: `JWT ${currentUser.token}`,
  //     },
  //   })
  //     .then((response) => {

  //       console.log(response)

  //       if (response && response.status === 200) {

  //         // setWalletBalance(response && response.data && response.data.balance)
  //       }


  //     })
  //     .catch((error) => {
  //       // setLoadVerify(false);
  //       console.log(error)
  //       console.log(error.response)
  //       // setTransactionMessage(error && error.response && error.response.data && error.response.data.message)
  //       // setTransaction(true)



  //     });

  // }

  const getUserCount = () => {
    axios({
      url: `${authEndpoint}agency/total_users/?timestamp=${new Date().getTime()}`,
      method: "get",
      headers: {
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then((response) => {

        console.log(response)

        if (response && response.status === 200) {

          setTotalUserCount(response && response.data && response.data.total_users_count)
        }


      })
      .catch((error) => {
        // setLoadVerify(false);
        console.log(error)
        console.log(error.response)
        // setTransactionMessage(error && error.response && error.response.data && error.response.data.message)
        // setTransaction(true)



      });
  }

  const getTotalTransfer = () => {
    axios({
      url: `${authEndpoint}agency/get_monthly_transfers/?timestamp=${new Date().getTime()}`,
      method: "get",
      headers: {
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then((response) => {

        console.log(response)

        if (response && response.status === 200) {

          setpendingPayouts(response && response.data && response.data.total_users_count)
        }


      })
      .catch((error) => {
        // setLoadVerify(false);
        console.log(error)
        console.log(error.response)
        // setTransactionMessage(error && error.response && error.response.data && error.response.data.message)
        // setTransaction(true)



      });
  }


  const ReferralBonus = () => {
    // setLoading(true);


    axios({
      url: `${authEndpoint}agency/getUserReferralStats/?timestamp=${new Date().getTime()}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then(async (response) => {
        // setLoading(false);
        console.log(response);
        if (response.data) {
          setTotalPaid(response && response.data && response.data.total_referral_bonus);
          setTotalPending(response && response.data && response.data.total_pending_savings_bonus);
          // setTotalBonus(response && response.data && response.data.total_pending_savings_bonus);

        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const AgentList = () => {
    // setLoading(true);

    axios({
      url: `${authEndpoint}agency/user_list/?timestamp=${new Date().getTime()}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then(async (response) => {
        // setLoading(false);
        console.log(response.data);
        if (response.data) {
          console.log("referral list",response && response.data);

          setReferralList(response && response.data);
          //    setModalBasic(true)
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error.response);
        //     console.log(Object.values(error.response.data))
        //     if(error && error.response && error.response.data && Object.values(error.response.data)[0] === "user has already made request for agency"){
        //         console.log(error.response);

        //     setModalPending(true);
        //   }
      });
  };


  const Referrallink = () => {
    // setLoading(true);


    axios({
      url: `${authEndpoint}users/getMyReferralLink/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then(async (response) => {
        // setLoading(false);
        console.log(response);
        if (response.data) {
          setReferralLink(response && response.data && response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };



  useEffect(() => {

    // getCommissionBalance();
      // getWalletBalance();
    // getUserFloat();
     getUserCount();
     getTotalTransfer();
    ReferralBonus();
    AgentList();
    Referrallink();
    


  }, [])

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>dashboard | herconomy - Agency</title>
        </MetaTags>
        <Container fluid>
          <h4>Agency Dashboard </h4>
          <Row>
            <Col lg="6">
              <Col xl="12">
                <Card>


                  <CardBody style={{ background: "#373839" }} className="top-card" >
                    <div className="welcomediv ">

                      <div>

                        <WelcomeIcon className="welcomeIcon" />

                      </div>
                    </div>
                    <div>


                      <div>
                        <h5 className="text-white welcometext">Welcome Back{' '}{currentUser.first_name}{' '}{currentUser.last_name}</h5>




                      </div>


                      {/* <i className="mdi mdi-account-circle text-warning h1" style={{margin-bottom}}></i> */}
                      <img
                        src={currentUser.photo}
                        alt=""
                        className="welcomepic"
                      />
                    </div>
                  </CardBody>

                  <CardBody className="bottom-welcome">
                    <Row>



                    </Row>
                  </CardBody>


                </Card>
              </Col>
            </Col>
            <Col lg="6">
              <Row>
                <Col>
                  <SummaryCard
                    title="Total Referral Count"
                    description={totalPaid}
                    iconClass={<TotalRefer />}
                    tooltipMessage="This is the Total  Refferal Count "

                    nairasymbol="true"
                    classNa="total"
                  />
                </Col>
                <Col>
                  <SummaryCard
                    title="Referal Payouts Earned"
                    description={totalPaid}
                    iconClass={<ReferPayout />}
                    tooltipMessage="This is the Total amount earned for this month"
                    classNa="payout"


                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SummaryCard
                    // title={`New Users ${thisMonth}   ${thisYear}`}
                    title="Pending Payouts"
                    description={totalPending}
                    iconClass={<Pendingpayouts />}
                    arrow={<BsArrowUp className="me-2 text-warning font-size-22" />}
                    classNa="total"
                  />
                </Col>
                <Col>
                  <SummaryCard
                    title="Commissions"
                    description={commissions || "0"}
                    tooltipMessage="This is the Total Amount Withdrawed "

                    iconClass={<Commisions />}
                    classNa="total"
                    claim={commissions? "claim" : ""}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>

              </Card>
            </Col>
          </Row>

          <Row>

            <Container fluid>
              <Row>
                <Card>

                </Card>
              </Row>
            </Container>

            <Row>
              <Col className="col-12">
                <Card className="report-width">
                  <CardBody>
                    <h4>Referral List </h4>
                    
                               
                              
                    


                    {referlists && referlists.length === 0 && <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={referlists}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={referlists}

                        >
                          {toolkitProps => (
                            <React.Fragment>


                              <Row>
                                <Col xl="12"

                                >
                                  {/* {referlists && referlists.length === 0 &&<> <p>No one has signed up using your referral link yet<span style={{marginLeft:"10px"}}><span role="img" aria-label="dog">😟</span></span></p></>} */}
                                   <div
                                                             className="searchdiv"

                                   >

                                  
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                                
                                {showCopiedTooltip ? (
                      <div className=" refferal-tooltip float-end">
                        Copied
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='referral-link'>
                      <div className="referral-content" >


                        <p className=" text-dark" style={{ fontWeight: "bold" }}>Referral Code: <span className="">{referral_link} </span></p>
                        <Button
                          style={{ background: " #373839", borderRadius: "20px", padding: "5px 10px 5px 10px", marginBottom: "5px" ,display:"flex"}}
                          // className="linkbtn"
                          onClick={() => {
                            if (referral_link) {
                              if ('clipboard' in window.navigator) {
                                navigator.clipboard.writeText(currentUser?.username);
                                setShowCopiedToolTip(true);

                              }
                            }
                            setTimeout(() => {
                              setShowCopiedToolTip(false)
                            }, 2000);
                          }}
                        >
                          <CopyIcon

                            color="warning"

                            className="text-warning  font-weight-semibold me-3"
                            style={{ fontWeight: '600', size: "100px" }}


                          /> <span className="text-warning">Copy</span>
                        </Button>


                      </div>
                    </div>

                    <div className="link-wrapper">
              <Button
                color="dark"
                className="text-dark font-weight-semibold"
                style={{  borderRadius: "20px", width:"100%"}}

                onClick={() => {
                  if (referral_link) {
                    navigator.clipboard.writeText(referral_link);
                    setShowCopiedToolTipLink(true);
                  }
                  setTimeout(() => {
                    setShowCopiedToolTipLink(false);
                  }, 500);
                }}
              >
                <CopyLinkIcon />
                
                <span className="text-warning "
                                          style={{ marginLeft: "5px" }}

                >Copy my unique link</span>
              </Button>
              {showCopiedTooltipLink && (
                <div className="invalid-feedback d-block referralist-tooltip copied">
                  Copied
                </div>
              )}
            </div>
          </div>
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>}

                    {referlists && referlists.result && referlists.result.length > 0 && <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={productData}

                        >
                          {toolkitProps => (
                            <React.Fragment>


                              <Row>
                                <Col xl="12">
                                  {/* {referlists && referlists.length === 0 &&<> <p>No one has signed up using your referral link yet<span style={{marginLeft:"10px"}}><span role="img" aria-label="dog">😟</span></span></p></>} */}

                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>}

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
          <Modal
            isOpen={modalFundAccount}
            toggle={() => setModalFundAccount(!modalFundAccount)}
            style={{ borderRadius: '20px' }}
            className="complaince-modal"
          >
            <ModalBody>
              <Row className="message-cont">

                <p className="h4 amount">
                  {FloatAccount && <>{FloatAccount}</>}
                  {/* {!loading && !FloatAccount && <>No Account</>} */}
                </p>
                <h6 className="percent-per-anum bank text-capitalize">
                  {FloatBank && (
                    <>
                      {FloatBank}{' '}
                      {!FloatBank.toLowerCase().includes('bank') &&
                        'Bank'}
                    </>
                  )}
                  {!loading && !FloatBank && '--'}
                </h6>


              </Row>
            </ModalBody>
          </Modal>

          <Row>
            <Col lg="6">
              <LineChart
                graphTitle={"Refferal Count"}
                labelNames={[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apl",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ]}
                dataLabel={"Active Savers"}
                dataValues={[
                  // 800, 950, 1070, 1190, 1300, 1420, 1510, 1660, 1790, 1900,
                  // 1990, 2100,
                  0,0,0,0,0,0,0,0,4, 10,
                  20, 50,
                ]}
              />
            </Col>
            <Col lg="6">
              <LineChart
                graphTitle={"Commission"}
                labelNames={[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apl",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ]}
                dataLabel={"Active Savers"}
                dataValues={[
                  // 800, 950, 1070, 1190, 1300, 1420, 1510, 1660, 1790, 1900,
                  // 1990, 2100,
                  0,0,0,0,0,0,0,0, 1790, 1900,
                   1990, 2100,
                ]}
              />
            </Col>
          </Row>
        </Container>


      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  currentUser: PropTypes.object,


};

const mapStateToProps = (state) => {
  const { currentUser } = state.Login;
  console.log(state)
  return { currentUser };
};

export default connect(mapStateToProps, {
})(Dashboard);
