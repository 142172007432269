import axios from 'axios';
// import { currentUser } from 'constants/defaultValues';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";


const useFloat = ({ currentUser}) => {
  const float = useSelector((state) => state.agency.float);
  console.log(float)
  const [floatBalance, setFloatBalance] = useState(float.floatBalance);
  const [FloatAccount, setFloatAccount] = useState(float.floatAccount);
  const [FloatBank, setFloatBank] = useState(float.floatBank);
  const [errorMessage, setErrorMessage] = useState('');
  const [verifyPhoneModal, setVerifyPhoneModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allTransactions, setFloatTransaction] = useState(
    float.floatTransaction
  );

  console.log(currentUser)
  const {
    token,
    // gql_token
  } = currentUser;
  const authEndpoint = process.env.REACT_APP_AUTHENDPOINT;

  const getUserTransactions = async () => {
    try {
      const { data } = await axios.get(
        `${authEndpoint}float/user/getUserFloatTransactions/?timestamp=${new Date().getTime()}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );
      console.log(data)
      setFloatTransaction(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getUserFloat = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${authEndpoint}float/user/getUserFloat/?timestamp=${new Date().getTime()}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );

      console.log('user float', data);
      setLoading(false);
      setFloatBalance(data.balance);
      setFloatAccount(data.float_accounts[0].account_number);
      setFloatBank(data.float_accounts[0].account_bank);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
    }
  };

  const generateBankAccount = async () => {
    // if (!phone_number_verified) {
    //   setVerifyPhoneModal(true);
    //   return;
    // }
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${authEndpoint}float/user/generateMonnifyNuban/?timestamp=${new Date().getTime()}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );
      setLoading(false);
      if (data.detail) {
        getUserFloat();
      } else {
        setFailureModal(true);
        setErrorMessage(
          'There was a problem fetching float accout numbers. Please try again.'
        );
      }
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      setFailureModal(true);
      setErrorMessage(
        'There was a problem fetching float accout numbers. Please try again.'
      );
    }
  };

  return {
    getUserFloat,
    getUserTransactions,
    floatBalance,
    FloatAccount,
    allTransactions,
    FloatBank,
    // generateBankAccount,
    verifyPhoneModal,
    setVerifyPhoneModal,
    failureModal,
    setFailureModal,
    loading,
    errorMessage,
  };
};


useFloat.propTypes = {
  currentUser: PropTypes.object,


};

export default useFloat;
