import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Chart.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Gold and Free Plan Users Comparison",
    },
  },
};



const ComparisonChart = ({ labelNames, dataSetList, graphTitle }) => {
  const labels = labelNames;

  const data = {
    labels,
    datasets: dataSetList,
  };
  return (
    <Card className="mini-stats-wid">
      <CardBody>
        <CardTitle className="mb-4">
          {graphTitle}
        </CardTitle>{" "}
        <Bar options={options} data={data} />{" "}
      </CardBody>{" "}
    </Card>
  );
};

ComparisonChart.propTypes = {
  labelNames: PropTypes.array.isRequired,
  dataSetList: PropTypes.array.isRequired,
  graphTitle: PropTypes.string.isRequired,
}

export default ComparisonChart;
