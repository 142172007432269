import React,{useState} from "react";
import { Col, Card, CardBody,Button } from "reactstrap";
import PropTypes from "prop-types";
import { ReactComponent as InfoIcon } from '../../assets/images/logos/info.svg';
import Numeral from 'react-numeral';


import { BsArrowUp } from "react-icons/bs";



const SummaryCard = ({ title, description, iconClass,tooltipMessage ,symbol, arrow ,classNa,claim}) => {
  const [showToolTip, setShowToolTip] = useState(false);
console.log(description)
  return (
    <>
      <Col>
        <Card className="mini-stats-wid">
          <CardBody>

            <div className="d-flex">
            <div className="avatar-sm rounded-circle ms-3 mr-3 align-self-center mini-stat-icon" style={{background:"#373839"}}>
                <span className="avatar-title rounded-circle  "  style={{background:"#373839"}}
>
                  {iconClass}
                </span>
              </div>
                 <div className={`d-flex-column ${classNa}`}>
                <p className="text-dark fw-medium">{title}</p>
                
                <div className="d-flex">

                 <h4 className="mb-0 me-3"><span>&#8358;</span>  <Numeral value={description || "0"} format="0,0.00" />
 </h4>
                {claim && <Button type="button" color="warning"
                        className="text-dark font-weight-semibold " style={{marginTop:"-10px"}}
                        disabled
                      >
                        Claim
                      </Button>
                     }
                </div>

                 </div>


            </div>
 
            {/* <div className="d-flex mb-5">
              <div className="flex-grow-1">
              {iconClass && <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                  {iconClass}
                </span>
              </div>}
                {symbol && <span>&#8358;</span>}
                <p className="text-dark fw-medium">{title}</p>
                <div className="d-flex">

                {arrow && arrow}
                <h4 className="mb-0"> {description}</h4>
                </div>
              </div>
            
            
            </div> */}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

SummaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconClass: PropTypes.object,
  tooltipMessage: PropTypes.string,
  arrow: PropTypes.object,
  symbol: PropTypes.object,
  classNa: PropTypes.string,
  claim: PropTypes.string,





};

export default SummaryCard;
