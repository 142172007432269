import React, { Component,useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, CardTitle, Container,Modal,ModalBody,Button } from "reactstrap";
import { FaUserTie } from "react-icons/fa"
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";


const ModalPop = ({image,title,message,cancel,ok})=> {

    const [modalError, setModalError] = useState(true);
    const history = useHistory();

  return (
    <div>
      {/* <div className="rightbar-overlay"> */}
         <Modal
            isOpen={modalError}
            toggle={() => setModalError(!modalError)}
            style={{ borderRadius: '20px' }}
            className="complaince-modal"
          >
            <ModalBody>
              <Row className="message-cont">

               {/* <FaUserTie className="compliance mb-5" /> */}
           {image && <img src={image} alt="" className="compliance"
 />}


                <p className=" h4   font-weight-medium text-center para-head-center">
                  {title && title}
                </p>

                <p className="h6 text-muted mb-3">
   {message && message}
                </p>

  <div className="d-flex justify-content-center align-items-center">   

  <Button
                                    type="button"
                                    color="warning"
                                    className="btn-rounded text-dark mb-2 me-2"
                                     onClick={()=>{
                                    history.push('/compliance')
                                  }
                                     }
                                  >
                                    {/* <i className="mdi mdi-plus me-1" /> */}
                                  {cancel}
                                  </Button>

                                  <Button
                                    type="button"
                                    color="warning"
                                    className="btn-rounded text-dark mb-2 me-2"
                                     onClick={()=>{
                                    history.push('/compliance')
                                  }
                                     }
                                  >
                                    {/* <i className="mdi mdi-plus me-1" /> */}
                               {ok} 
                                                                </Button>
                                  </div>


              </Row>
            </ModalBody>
          </Modal>
        {/* </div> */}
    </div>
  );



}
ModalPop.propTypes={
  image: PropTypes.string,
title: PropTypes.string,
message: PropTypes.string,
ok: PropTypes.string,
cancel: PropTypes.string,
}
export default ModalPop;