import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import PropTypes from "prop-types"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';


// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

export const options = {
  // responsive: false,
  //maintainAspectRatio: false,
  // scales: {
  //   xAxes: [{
  //     barPercentage: 0.4,
  //     minBarLength: 100
  //   }],
  // }
  // plugins: {
  //   legend: {
  //     position: "top",
  //   },
  //   title: {
  //     display: true,
  //     // text: "Chart.js Line Chart",
  //   },
  // },
};



const LineChart = ({
  labelNames, dataValues, dataLabel, graphTitle
}) => {

  const labels = labelNames;

  // const label = "transfer"
  //  const graphTitle = "inflow"
  const days = [
    "monday", "tuesday", "wednesday", "tuesday", "friday", "saturday", "sunday"
  ]
  // const amount= [100,50,300,294,297,340];
  console.log(dataValues)
  console.log(dataLabel)
  console.log(graphTitle)
  console.log(labels)




  const data = {
    labels,
    datasets: [
      {
        label: graphTitle,
        data: dataValues,
        borderColor: "rgb(68, 88, 184)",
        backgroundColor: "rgba(244, 187, 1, 1)",

      },
    ],
  };

  return (
    <Bar options={options} data={data} className="transferbar"/>
  );
};

LineChart.propTypes = {
  labelNames: PropTypes.array.isRequired,
  dataValues: PropTypes.array.isRequired,
  dataLabel: PropTypes.string.isRequired,
  graphTitle: PropTypes.string.isRequired
};

export default LineChart;
