import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Badge,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from "reactstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Numeral from 'react-numeral';


import classnames from "classnames";
import { useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import fileDownload from 'js-file-download'

import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from 'react-router-dom';


import Success from "../Components/Success";
import axios from "axios";
import SummaryCard from "pages/Components/SummaryCard";
import { ReactComponent as InfoIcon } from '../../assets/images/logos/info.svg';
import { ReactComponent as InterestIcon } from '../../assets/images/logos/percent.svg';
import { ReactComponent as BulbIcon } from '../../assets/images/logos/bulb.svg';
import { ReactComponent as SavingsDayIcon } from '../../assets/images/logos/vault-savings-day-logo.svg';
import LineChart from "pages/Components/LineChart";


const authEndpoint = process.env.REACT_APP_AUTHENDPOINT;





const Commision = ({ currentUser }) => {

  const [activeTab, setActiveTab] = useState("1");
  const [isMenu, setIsMenu] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [modalBasic, setModalBasic] = useState(false);
  const [commissionInterest, setCommissionInterest] = useState(0);
   const [loading, setLoading] = useState(false);
   const [showToolTip, setShowToolTip] = useState(false);
   const [commissionList, setCommissionList] = useState([]);


   const columns = [{
    dataField: 'id',
    text: 'S/No',
    sort: true,
  }, {
    dataField: 'month',
    text: 'Month',
    sort: true
  }, {
    dataField: 'amount',
    text: 'Amount',
    sort: true
  }, {
    dataField: 'reference',
    text: 'Reference Number',
    sort: true
  },
  
  ];

  const productData = commissionList && commissionList.result && commissionList.result.length > 0 && commissionList.result.map((item, index) => {
    console.log(item)
  
    return {
      id: index + 1,
      month: item.month && item.month,
      amount:item.amount &&  item.amount,
      reference: item.reference && item.reference,
     



    }
  })

   const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const d = new Date(new Date().getFullYear(), new Date().getMonth(), 1);


   
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: commissionList.length === 0 ? 0 : productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: commissionList.length === 0 ? 0 : (productData).length }];




  const options = {
    chart: { toolbar: !1, zoom: { enabled: !0 } },
    plotOptions: {
      candlestick: { colors: { upward: "#34c38f", downward: "#f46a6a" } },
    },
    xaxis: { type: "datetime" },
    yaxis: { tooltip: { enabled: !0 } },
  }

  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }



  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };


  const getWalletBalance = () => {

    axios({
      url: `${authEndpoint}transaction/getTransactionWallet/`,
      method: "get",
      headers: {
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then((response) => {

        console.log(response)

        if (response && response.status === 200) {

          setWalletBalance(response && response.data && response.data.balance)
        }


      })
      .catch((error) => {
        // setLoadVerify(false);
        console.log(error)
        console.log(error.response)
        // setTransactionMessage(error && error.response && error.response.data && error.response.data.message)
        // setTransaction(true)



      });

  }

  useEffect(() => {

    getWalletBalance();

  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <MetaTags>
          <title> Herconomy Agency</title>
        </MetaTags> */}
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Commission" breadcrumbItem=" Commision Buy/Sell" /> */}
          <div className="font-size-18">COMMISSION </div>


           <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/*
                  <div className="float-end">
                    <Dropdown
                      isOpen={isMenu}
                      toggle={toggleMenu}
                    >
                      <DropdownToggle
                        type="button"
                        tag="button"
                        className="btn btn-light"
                      >
                        <i className="mdi mdi-wallet me-1" />
                        <span className="d-none d-sm-inline-block">
                          Wallet Balance <i className="mdi mdi-chevron-down" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end dropdown-menu-md">
                        <div className="dropdown-item-text">
                          <div>
                            <p className="text-muted mb-2">Available Balance</p>
                            <h5 className="mb-0">₦{walletBalance}</h5>
                          </div>
                        </div>

                        <DropdownItem divider />

                        <DropdownItem href="#">
                          Wallet : <span className="float-end">1.02356</span>
                        </DropdownItem>
                        <DropdownItem href="#">
                          Commission : <span className="float-end">0.04121</span>
                        </DropdownItem>
                        <DropdownItem href="#">

                        </DropdownItem>

                        <DropdownItem divider />

                        <DropdownItem
                          className="text-primary text-center"
                          href="#"
                        >
                          Learn more
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div> */}
                  {/* <h4 className="card-title mb-4">Commission</h4> */}
                  <div className="crypto-buy-sell-nav ">
                    <Nav tabs className="nav-tabs-custom text-warning" role="tablist">
                      <NavItem>
                        <NavLink
                          // className={classnames({
                          //   active: activeTab === "1",
                          // })}
                          className="text-warning"
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          Commission
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          Sell
                        </NavLink>
                      </NavItem> */}
                    </Nav>

                    <TabContent
                      activeTab={activeTab}
                      className="crypto-buy-sell-nav-content p-4"
                    >
                      <TabPane tabId="1" id="buy">

                        <div className="mb-2">
                          {/* <Label>Currency :</Label> */}

                          <Row>
                            <Col xl="12" sm="12">
                              <div className="mb-3">

                                {/* <NavLink to="/app/autosavesetting"> */}
                                <Card
                      style={{
                        flexWrap: 'wrap',
                        border: 'solid #f7bd00 2px',
                        boxShadow: 'none',
                      }}
                    >
                      <CardBody>
                        {/* <i className={icon} /> */}
                        {/* <p className="card-text font-weight-semibold ml-3">
                          Vault
                        </p> */}

                        <p className="card-text font-weight-semibold ml-3 h3 d-flex justify-content-between">
                          {/* <NavLink to="/app/vault"> */}
                          {monthNames[d.getMonth()]}{' '}
              {d.getFullYear()}.

                          {/* <IntlMessages id="Vault" />  */}
                          {/* </NavLink> */}
                          {/* <div className="mb-5 float-end position-relative">
                           <InfoIcon
                              onClick={() => {
                                setShowToolTip(!showToolTip);
                              }}
                            />
                            {showToolTip ? (
                              <div className=" d-block info-tooltip">
                                This is generated based on the saving habits of your referrals
                             
                              </div>
                            ) : (
                              ''
                            )}
                          </div> */}
                        </p>

                        <p className="h3 font-weight-bold ml-3 ">
                          <span>&#8358;</span>
                          {/* {balance ? (
                            <Numeral value={vault.balance} format="0,0.00" />
                          ) : ( */}
                            <Numeral value="2342.66" format="0,0.00" />
                          {/* )} */}
                        </p>

                        <div className="text-dark text-medium text-left h6 font-weight-medium  ">
                        This is generated based on the saving habits of your referrals
                      </div>
                  
                      
                      </CardBody>
                    </Card>


                 
                <div className='commision__text mb-5'>
                <div className="text-dark text-medium text-center h6 font-weight-medium  ">
                       Your Commision is calculated as 0.5% of whatever your referrals saves with Herconomy.
                       You earn this for one year.
                      </div>
                </div>
           
                              </div>
                             
                            




                            </Col>


  <Row>
              <Col className="col-6">
                <Card className="report-width">
                  <CardBody>
                    <h4>Commission List </h4>
                    
                               
                              
                    


                    {commissionList && commissionList.length === 0 && <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={commissionList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={commissionList}

                        >
                          {toolkitProps => (
                            <React.Fragment>


                              <Row>
                                <Col xl="12"

                                >
                                 
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>}

                    {commissionList && commissionList.result && commissionList.result.length > 0 && <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={productData}

                        >
                          {toolkitProps => (
                            <React.Fragment>


                              <Row>
                                <Col xl="12">
                                  {/* {commissionList && commissionList.length === 0 &&<> <p>No one has signed up using your referral link yet<span style={{marginLeft:"10px"}}><span role="img" aria-label="dog">😟</span></span></p></>} */}

                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>}

                  </CardBody>
                </Card>
              </Col>

              <Col lg="6">
              <LineChart
                graphTitle={"Commission"}
                labelNames={[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apl",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ]}
                dataLabel={"Active Savers"}
                dataValues={[
                  // 800, 950, 1070, 1190, 1300, 1420, 1510, 1660, 1790, 1900,
                  // 1990, 2100,
                  0,0,0,0,0,0,0,0, 1790, 1900,
                   1990, 2100,
                ]}
              />
            </Col>
            </Row>








                          </Row>
                        </div>





                      </TabPane>
                    </TabContent>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

         
        </Container>
      </div>

    </React.Fragment>
  );
};

Commision.propTypes = {
  currentUser: PropTypes.object,


};

const mapStateToProps = (state) => {
  const { currentUser } = state.Login;
  console.log(state)
  return { currentUser };
};

export default connect(mapStateToProps, {
})(Commision);