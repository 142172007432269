import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
} from "./actionTypes"

import {
  getCurrentUser,
  // getUserDetail
} from '../../../helpers/Utils';

import {
  //  isAuthGuardActive,
  currentUser,
} from '../../../pages/constants/defaultValues';

const initialState = {
  currentUser: getCurrentUser() || currentUser,
  registrationError: null,
  message: null,
  loading: false,
  user: null,
}



const register = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        currentUser: action.payload,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default register
