export const columns = [{
    dataField: 'id',
    text: 'S/No',
    //   sort: true,
  }, {
    dataField: 'name',
    text: 'Full Name',
    //   sort: true
  }, {
    dataField: 'email',
    text: 'Email',
    //   sort: true
  }, {
    dataField: 'phoneno',
    text: 'Phone Number',
    //   sort: true
  }
    , {
    dataField: 'easy save?',
    text: 'Easy save?',
    //   sort: true
  },
  {
    dataField: 'frequency of savings',
    text: 'Frequency of Savings',
    //   sort: true
  }
  
  ];