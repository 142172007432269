import React from 'react';
import { PropTypes } from 'prop-types';





const PermissionError = (props)=>{


    return (
        <React.Fragment>
          <div >
        <p>Access restricted. </p>
        <p>You are currently unable to access this dashboard, 
        please contact support@herconomy.com to enable your access  </p>

          </div>
        </React.Fragment>
    );

}


PermissionError.PropTypes = {

}

export default PermissionError;