import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody,Button } from "reactstrap";
import { Link } from "react-router-dom";

//Images
import client1 from "../../../../assets/images/clients/1.png";
import client2 from "../../../../assets/images/clients/2.png";
import client3 from "../../../../assets/images/clients/3.png";
import client4 from "../../../../assets/images/clients/4.png";
import client5 from "../../../../assets/images/clients/5.png";
import client6 from "../../../../assets/images/clients/6.png";

import aboutus from "../../../../assets/images/users/about.png"


const AboutUs = () => {
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);

  return (
    <React.Fragment>
      <section className="section pt-4 bg-white" id="about">
        {/* <div className="bg-overlay bg-primary" /> */}
        <Container>
          <Row>
            {/* <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">About us</div>
                <h4>What is ICO Token?</h4>
              </div>
            </Col> */}
          </Row>
          <Container>
          <Row className="align-items-center aboutspace">
          <Col lg="5" md="6" sm="8" className="mt-5 mt-lg-0">
            <img
            className="overflow-hidden mb-0 mt-5 aboutimg "
            // src={props.currentUser.photo ? props.currentUser.photo : aboutus }
            src={ aboutus }

            alt="Header Avatar"
          />
            </Col>
            <Col lg="5" className="ms-lg-auto">
              <div className="text-dark-50 about-text-space">
                <h2 className="text-dark font-weight-semibold about-title">
                About Us                </h2>
                <br/>
                <p className="landingfont">
                Herconomy is on a mission to close the gap of financial
inclusion for women in Nigeria. With our agency network, every woman has the opportunity to help us achieve this dream while earning money by the side. </p>

<a
 href ="http://herconomy.com/who-we-are/"
  target="_blank"
 rel="noreferrer noopener"
> <Button className="btn btn-dark font-weight-semibold font-size-16 text-white me-1  about-btn" style={{borderRadius:"5px"}}>
                   Read More
                  </Button>
                  </a>
                <div className="d-flex flex-wrap gap-2 mt-4">
                  {/* <Link to="#" className="btn btn-warning me-1">
                    Register
                  </Link> */}
                  {/* <Link to="#" className="btn btn-light">
                    How it work
                  </Link> */}
                </div>
              </div>
            </Col>
           
          </Row>
        </Container>

          <hr className="my-5" />

          {/* <Row>
            <Col lg="12">
              <Col lg="12">
                <div className="hori-timeline">
                  <div
                    className="owl-carousel owl-theme  navs-carousel events"
                    id="timeline-carousel"
                  >
                    {step1 ? (
                      <>
                        <Row>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client1}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client2}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client3}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client4}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : null}

                    {step2 ? (
                      <>
                        <Row>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client4}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client5}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client6}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : null}

                    <div className="owl-nav" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        onClick={() => {
                          setStep1(true);
                          setStep2(false);
                        }}
                        className="border-0"
                        disabled={step1}
                      >
                        <i className="mdi mdi-chevron-left" />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setStep1(false);
                          setStep2(true);
                        }}
                        className="border-0"
                        disabled={step2}
                      >
                        <i className="mdi mdi-chevron-right" />
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row> */}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
