import React, { Component, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Row, Col, Card, CardBody, CardTitle, Container, Modal, ModalBody, Button, Dropdown, Input, FormGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SummaryCard from "../Components/SummaryCard";
import MonthlyReg from "../Components/MonthlyReg";
import ComparisonChart from "../Components/ComparisonChart";
import LineChart from "../Components/LineChart";
import UserList from "../Components/UserList";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import fileDownload from 'js-file-download'
import { CSVLink } from 'react-csv';
import jsPDF from "jspdf"
import "jspdf-autotable"
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";




import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../User_Management"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Success from "../Components/Success";
import Download from "./Download";





const { SearchBar } = Search;

const Report = ({ currentUser , productData ,pageOptions,columns }) => {
  const [modalError, setModalError] = useState(true);
  const [menu, setMenu] = useState(false)




  const generatePdf = () => {

    let doc = new jsPDF();
    console.log(doc)
  
    doc.text(20, 20, 'This is the first page title.')
  
    doc.setFont('helvetica')
    doc.text(20, 60, 'This is the content area.')
    doc.autoTable({
      theme: 'grid',
      startY: 20,
      head: [['S/N0', 'Name', 'Email', 'Phone No', 'Auto-Save', 'Frequency', 'salary']],
      body: data1,
    });
    doc.save('sample-file.pdf')
  }
  
//   const columns = [{
//     dataField: 'id',
//     text: 'S/No',
//     //   sort: true,
//   }, {
//     dataField: 'name',
//     text: 'Full Name',
//     //   sort: true
//   }, {
//     dataField: 'email',
//     text: 'Email',
//     //   sort: true
//   }, {
//     dataField: 'phoneno',
//     text: 'Phone Number',
//     //   sort: true
//   }
//     , {
//     dataField: 'easy save?',
//     text: 'Easy save?',
//     //   sort: true
//   },
//   {
//     dataField: 'frequency of savings',
//     text: 'Frequency of Savings',
//     //   sort: true
//   }

//   ];
  
  
  const productData2 = [[1, "Miriam Dike", "tochi@herconomy.com", "08083844955", "yes", "Daily"], [1, "Miriam Dike", "tochi@herconomy.com", "08083844955", "yes", "Daily"]]
  
  
  const data1 = productData.map((item) => {
  
    return [item.id, item.name, item.position, item.office, item.age, item.startdate, item.salary]
  
  }
  );
   //and map all the data you require.
  
  
  
  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  
//   const pageOptions = {
//     sizePerPage: 10,
//     totalSize: productData.length, // replace later with size(customers),
//     custom: true,
//   }
  
  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }];
  
  
  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category: '',

      // username: '',
      // password: '',
    },
    validationSchema: Yup.object({

      category: Yup.string().required("Please Enter Your Catergory"),
      // invalidCheck:Yup.boolean().oneOf([true],'Message'),
    }),
    onSubmit: (values) => {

      console.log(values)
      console.log(history)
    }
  });
  return (
    <React.Fragment>
   
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                 {/* <Download generatePdf={generatePdf} productData={productData}/> */}


                 
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            {/* <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    {console.log(toolkitProps.searchProps)}
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row> */}

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
      
      {/* {currentUser.onboarded === false && 

 <Success />
         } */}
    </React.Fragment>
  );
};

Report.propTypes = {
  currentUser: PropTypes.object,
  productData: PropTypes.array,
  pageOptions:PropTypes.object,
  columns:PropTypes.array,


};

const mapStateToProps = (state) => {
  const { currentUser } = state.Login;
  console.log(state)
  return { currentUser };
};

export default connect(mapStateToProps, {
})(Report);
