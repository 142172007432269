// import { configureStore } from "@reduxjs/toolkit";

// //Reducers
// import userReducer from "../features/userSlice";
// import Layout from "./layout/reducer";
// import Profile from "./auth/profile/reducer"
// import Dashboard from "./dashboard/reducer"


// export default configureStore({
//     reducer: {
//         user: userReducer,
//         Layout,
//         Profile,
//         Dashboard
//     }
// })

import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"

import rootReducer from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(rootSaga)

export default store
