import React, { Component,useState } from "react";
import MetaTags from "react-meta-tags";
import {  Row, Col, Card, CardBody, CardTitle, Container,Modal,ModalBody,Button, Dropdown,Input,FormGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, } from "reactstrap";
import SummaryCard from "../Components/SummaryCard";
import MonthlyReg from "../Components/MonthlyReg";
import ComparisonChart from "../Components/ComparisonChart";
import LineChart from "../Components/LineChart";
import UserList from "../Components/UserList";
import PropTypes from "prop-types";
import { useSelector,connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import fileDownload from 'js-file-download'
import { CSVLink } from 'react-csv';
import jsPDF from "jspdf"
import "jspdf-autotable"
import {  Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";



import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Success from "../Components/Success";



const generatePdf = () => {
 
  let  doc = new jsPDF();
  console.log(doc)

  doc.text(20, 20, 'This is the first page title.')

  doc.setFont('helvetica')
  doc.text(20, 60, 'This is the content area.')
  doc.autoTable({
    theme: 'grid',
    startY: 20,
    head: [['S/N0', 'Name', 'Email','Phone No','Auto-Save','Frequency','salary']],
    body:data1,
});



  doc.save('sample-file.pdf')
}

const columns = [{
  dataField: 'id',
  text: 'S/No',
  sort: true,
}, {
  dataField: 'name',
  text: 'Full Name',
  sort: true
}, {
  dataField: 'position',
  text: 'Email',
  sort: true
}, {
  dataField: 'office',
  text: 'Phone Number',
  sort: true
}, {
  dataField: 'age',
  text: 'Easy-Save',
  sort: true
}, {
  dataField: 'startdate',
  text: 'Frequency of Savings',
  sort: true
}];


const productData2 = [[ 1, "Miriam Dike",  "tochi@herconomy.com",  "08083844955",  "yes",  "Daily"],[ 1, "Miriam Dike",  "tochi@herconomy.com",  "08083844955",  "yes",  "Daily"]]


// Table Data
const productData = [
  { "id": 1, "name": "Miriam Dike", "position": "tochi@herconomy.com", "office": "08083844955", "age": "yes", "startdate": "Daily", "salary": "$162,700" },
  
  { "id": 2, "name": "Miriam Dike", "position": "tochi@herconomy.com", "office": "08083844955", "age": "yes", "startdate": "Daily", "salary": "$162,700" },
  
  { "id": 3, "name": "Miriam Dike", "position": "tochi@herconomy.com", "office": "08083844955", "age": "yes", "startdate": "Daily", "salary": "$162,700" },
  
  { "id": 4, "name": "Miriam Dike", "position": "tochi@herconomy.com", "office": "08083844955", "age": "yes", "startdate": "Daily", "salary": "$162,700" },
  
  { "id": 5, "name": "Miriam Dike", "position": "tochi@herconomy.com", "office": "08083844955", "age": "yes", "startdate": "Daily", "salary": "$162,700" },
  
  { "id": 6, "name": "Miriam Dike", "position": "tochi@herconomy.com", "office": "08083844955", "age": "yes", "startdate": "Daily", "salary": "$162,700" },
  
  { "id": 7, "name": "Miriam Dike", "position": "tochi@herconomy.com", "office": "08083844955", "age": "yes", "startdate": "Daily", "salary": "$162,700" },
  
  { "id": 8, "name": "Miriam Dike", "position": "tochi@herconomy.com", "office": "08083844955", "age": "yes", "startdate": "Daily", "salary": "$162,700" },
  
  { "id": 9, "name": "Cara Stevens", "position": "Sales Assistant", "office": "New York", "age": "46", "startdate": "2011/12/06", "salary": "$145,600" },
  
  { "id": 10, "name": "Cedric Kelly", "position": "Senior Javascript Developer", "office": "Edinburgh", "age": "22", "startdate": "2012/03/29", "salary": "$433,060" },
  
  { "id": 11, "name": "Marshall", "position": "Regional Director", "office": "San Francisco", "age": "36", "startdate": "2008/10/16", "salary": "$470,600" },
  
  { "id": 12, "name": "Hurst", "position": "Javascript Developer", "office": "San Francisco", "age": "39", "startdate": "2009/09/15", "salary": "$205,500" },
  
  { "id": 13, "name": "Rios", "position": "Personnel Lead", "office": "Edinburgh", "age": "35", "startdate": "2012/09/26", "salary": "$217,500" },
  
  { "id": 14, "name": "Snider", "position": "Customer Support", "office": "New York", "age": "27", "startdate": "2011/01/25", "salary": "$112,000" },
  
  { "id": 15, "name": "Wilder", "position": "Sales Assistant", "office": "Sidney", "age": "23", "startdate": "2010/09/20", "salary": "$85,600" },
  
  { "id": 16, "name": "Camacho", "position": "Support Engineer", "office": "San Francisco", "age": "47", "startdate": "2009/07/07", "salary": "$87,500" },
  
  { "id": 17, "name": "Green", "position": "Chief Operating Officer (COO)", "office": "San Francisco", "age": "48", "startdate": "2010/03/11", "salary": "$850,000" },
  
  { "id": 18, "name": "Winters", "position": "Accountant", "office": "Tokyo", "age": "63", "startdate": "2011/07/25", "salary": "$170,750" },
  
  { "id": 19, "name": "Cortez", "position": "Team Leader", "office": "San Francisco", "age": "22", "startdate": "2008/10/26", "salary": "$235,500" },
  
  { "id": 20, "name": "Joyce", "position": "Developer", "office": "Edinburgh", "age": "42", "startdate": "2010/12/22", "salary": "$92,575" },
  
  { "id": 21, "name": "Gloria Little", "position": "Systems Administrator", "office": "New York", "age": "59", "startdate": "2009/04/10", "salary": "$237,500" },
  
  { "id": 22, "name": "Haley Kennedy", "position": "Senior Marketing Desi,ner", "office": "London", "age": "43", "startdate": "2012/12/18", "salary": "$313,500" },
  
  { "id": 23, "name": "Hermione Butler", "position": "Regional Director", "office": "London", "age": "47", "startdate": "2011/03/21", "salary": "$356,250" },
  
  { "id": 24, "name": "Herrod Chandler", "position": "Sales Assistant", "office": "San Francisco", "age": "59", "startdate": "2012/08/06", "salary": "$137,500" },
  
  { "id": 25, "name": "Hope Fuentes", "position": "Secretary", "office": "San Francisco", "age": "41", "startdate": "2010/02/12", "salary": "$109,850" },
  
  { "id": 26, "name": "Howard Hatfield", "position": "Office Manager", "office": "San Francisco", "age": "51", "startdate": "2008/12/16", "salary": "$164,500" },
  
  { "id": 27, "name": "Jackson Bradshaw", "position": "Director", "office": "New York", "age": "65", "startdate": "2008/09/26", "salary": "$645,750" },
  
  { "id": 28, "name": "Jena Gaines", "position": "Office Manager", "office": "London", "age": "30", "startdate": "2008/12/19", "salary": "$90,560" },
  
  { "id": 29, "name": "Jenette Caldwell", "position": "Development Lead", "office": "New York", "age": "30", "startdate": "2011/09/03", "salary": "$345,000" },
  
  { "id": 30, "name": "Jennifer Acosta", "position": "Junior Javascript Devel,per", "office": "Edinburgh", "age": "43", "startdate": "2013/02/01", "salary": "$75,650" }
  
];

 const data1 =productData.map((item)=>{
 
   return [item.id, item.name, item.position, item.office,item.age,item.startdate,item.salary]
   
   
  }
  ); //and map all the data you require.

const defaultSorted = [{
  dataField: 'id',
  order: 'asc'
}];

const pageOptions = {
  sizePerPage: 10,
  totalSize: productData.length, // replace later with size(customers),
  custom: true,
}

// Custom Pagination Toggle
const sizePerPageList = [
  { text: '5', value: 5 },
  { text: '10', value: 10 },
  { text: '15', value: 15 },
  { text: '20', value: 20 },
  { text: '25', value: 25 },
  { text: 'All', value: (productData).length }];


// Select All Button operation
const selectRow = {
  mode: 'checkbox'
}

const { SearchBar } = Search;

const UserManagement = ({currentUser}) => {
  const [modalError, setModalError] = useState(true);
  const [menu, setMenu] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category: '',
     
      // username: '',
      // password: '',
    },
    validationSchema: Yup.object({
     
      category: Yup.string().required("Please Enter Your Catergory"),
      // invalidCheck:Yup.boolean().oneOf([true],'Message'),
    }),
    onSubmit: (values) => {

      console.log(values)
      console.log(history)
    }
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Management | Herconomy - Agency</title>
        </MetaTags>
        <div className="container-fluid">
<div className="font-size-24">
  User Management
</div>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="float-end">



                  <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
        
                       <Button type="button" color="warning"
className="font-weight-semibold text-dark"
                            >
                              Download
                              {/* <i className="bx bx-download " /> */}


</Button>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
         
        <Button type="button" 
        color="warning"
        className="downlink font-weight-semibold text-dark"
                    onClick={()=>generatePdf()}

                            >
                              Download pdf


</Button>
  
                        
                          
                          <CSVLink
	data={productData}
	// headers={this.state.headers}
	filename='UserList.csv'
	target='_self'
  // className="downlink"
 
>

<Button type="button" 
        className="downlink font-weight-semibold text-dark"

                            >
	Download  csv


</Button>


</CSVLink>  
        </DropdownMenu>
      </Dropdown>
                    

                  </div>
                  <Row className="mb-5">
                      
                          <Col md="6">
                         <FormGroup className="mb-2">
                            <Input
                              name="category"
                              type="select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.category || ""}
                              invalid={
                                validation.touched.category && validation.errors.category ? true : false
                              }

                            >

                              <option></option>
                              <option>User List</option>
                              <option>User Savings Activity </option>
                            


                            </Input>
                            {/* {validation.touched.category && validation.errors.category ? (
                              <FormFeedback type="invalid">{validation.errors.category}</FormFeedback>
                            ) : null} */}

                          </FormGroup>
                          {/* </div> */}
                         </Col>

                        </Row>
                

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    {console.log(toolkitProps.searchProps)}
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
  {/* {currentUser.onboarded === false && 

 <Success />
         } */}
    </React.Fragment>
  );
};

UserManagement.propTypes = {
  currentUser: PropTypes.object,

  
};

const mapStateToProps = (state) => {
  const { currentUser} = state.Login;
  console.log(state)
  return { currentUser };
};

export default connect(mapStateToProps, {
})(UserManagement);
