import { agencyAccount, floatdetails } from 'constants/defaultValues';

// import { agencyAccount } from 'constants/defaultValues';
// import { REHYDRATE } from 'redux-persist';

// import { getCurrentVault } from 'helpers/Utils';
import {

  UPDATE_SETUP,
  ADD_FLOATDETAILS
 

} from './actionTypes';

const INIT_STATE = {
  agency: agencyAccount,
  float: floatdetails,


 
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case UPDATE_SETUP:
      return {
        ...state,
        loading: false,
        agency: { ...state.agency, withdrawal_account: action.payload },
      };
  
      case ADD_FLOATDETAILS:
        return {
          ...state,
          float: { ...state.float, ...action.payload },
        };

    default:
      return { ...state };
  }
};
