import React from "react"
import PropTypes from "prop-types"
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux"
//  import {selectUser} from "../features/userSlice"
import { Route, Redirect } from "react-router-dom"
import { getCurrentUser } from "../helpers/Utils";



const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const history = useHistory()


//   const setComponent = (props) => {
//     if (isAuthProtected) {
//       // const currentUser = getUserDetail();
//       const currentUser = getCurrentUser();

//       console.log(currentUser);
//       console.log(props)
//       if (currentUser) {
//         if (currentUser.token) {
//             return <Component {...props} />;
//           }
//           return (
//             <Redirect
//               to={{
//                 pathname: '/user/login',
//                 state: { from: props.location },
//               }}
//             />
//           );
//       }

      
//       return (
//         <Redirect
//           to={{
//             pathname: '/user/login',
//             state: { from: props.location },
//           }}
//         />
//       );
//     }
//     return <Component {...props} />;
//   };

//   return <Route {...rest} render={setComponent} />;
// };

  // const admin = localStorage.getItem('admin');
  const admin = true;
  console.log(admin)
  console.log(isAuthProtected)

  return (

  <Route
    {...rest}
    render={props => {
      const currentUser = getCurrentUser();
     console.log(currentUser)
     console.log(props)
     console.log(rest)

      if ( isAuthProtected &&
         currentUser.token===null) {
        return (
          <Redirect
            to={{ pathname: "/landing", state: { from: props.location } }}
          />
        )
      }

    //   if ( !isAuthProtected &&
    //     currentUser.token===null) {
    //    return (
    //      <Redirect
    //        to={{ pathname: "/landing", state: { from: props.location } }}
    //      />
    //    )
    //  }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
