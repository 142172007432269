import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"
import axios from 'axios';
import { setCurrentUser, getCurrentUser, setCurrentVault } from '../../../helpers/Utils';
const authEndpoint = process.env.REACT_APP_AUTHENDPOINT;

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper"

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend()



const registerWithEmailPasswordAsync = async (
  first_name,
  last_name,
  email,
  password1,
  password2,
  phone,
  category,
  business_type,
)=>  // eslint-disable-next-line no-return-await
  // await auth
  //   .signInWithEmailAndPassword(email, password)
  axios
  .post(`${authEndpoint}rest-auth/registration/`, {
    first_name,
    last_name,
    email,
    password1,
    password2,
    phone,
    category,
    business_type,

  })
    .then((response) => response.data)
    .catch((error) =>{
       console.log(error)
       console.log(error.response)
      return error.response

    } 
    );

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user,history } }) {
   console.log(user)
console.log(history)

  const details = {
    first_name : user.firstname,
      last_name: user.lastname,
      email:user.email,
      password1:user.password,
      password2:user.conpassword,
      phone: user.phonenumber,
      category: user.category,
      business_type : user.businesstype,


  }
  console.log(details)
  // businesstype: "3"
  // category: "2"
  // conpassword: "Bornagain1"
  // email: "chineduemordinodedev@gmail.com"
  // firstname: "chinedu"
  // invalidCheck: false
  // lastname: "emordi"
  // password: "Bornagain1"
  // phonenumber: 400
  try {
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const registerUser = yield call(registerWithEmailPasswordAsync, 
        details.first_name,
        details.last_name,
        details.email,
        details.password1,
        details.password2,
        details. phone,
        details.category,
        details. business_type,


        )
console.log(registerUser)

        if (registerUser.user && registerUser.token) {
          const item = {
            id: registerUser.user.id,
            email: registerUser.user.email,
            // photo: registerUser.user.photo,
            category: registerUser.user.category,
            compromised: registerUser.user.compromised,
            token: registerUser.token,
            is_agent: registerUser.user.is_agent,
            phone_number: registerUser.user.phone,
            first_name: registerUser.user.first_name,
            last_name: registerUser.user.last_name,
            business_type: registerUser.user.business_type,
          };
          console.log(item);
          setCurrentUser(item);
    



      yield put(registerUserSuccessful(item))
      history.push("/dashboard")

    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtRegister, "/post-jwt-register", user)
      yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeRegister, user)
      yield put(registerUserSuccessful(response))
    }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
