import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import SummaryCard from "../Components/SummaryCard";
import LineChart from "../Components/LineChart";
import ComparisonChart from "../Components/ComparisonChart";

import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FlagIcon from "@mui/icons-material/Flag";
import SavingsIcon from "@mui/icons-material/Savings";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Success from "../Components/Success";
import { useSelector,connect } from "react-redux";
import PropTypes from "prop-types";


const testdata = [
  {
    label: "Plans Created",
    data: [19, 29, 33, 14, 22, 31, 34, 24, 30, 15, 21, 39],
    backgroundColor: "rgba(53, 162, 235, 0.5)",
  }
];

const SavingsManagement = ({currentUser}) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Savings | Herconomy - Savings</title>
        </MetaTags>
        <Container fluid>
          <h4>Savings Management</h4>
          <Row lg="12">
            <Col lg="3" md="6" sm="6">
              <SummaryCard
                title="Total Savers"
                description="6,235"
                iconClass={<PersonAddAltIcon />}
              />
            </Col>
            <Col lg="3" md="6" sm="6">
              <SummaryCard
                title="Total Plans"
                description="745"
                iconClass={<FlagIcon />}
              />
            </Col>
            <Col lg="3" md="6" sm="6">
              <SummaryCard
                title="Total Vault Amount"
                description="N89,000,935"
                iconClass={<SavingsIcon />}
              />
            </Col>
            <Col lg="3" md="6" sm="6">
              <SummaryCard
                title="Total Float Amount"
                description="N35,000,000"
                iconClass={<AccountBalanceWalletIcon />}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <LineChart
                graphTitle={"Active Savers Growth Graph"}
                labelNames={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
                dataLabel={"Active Savers"}
                dataValues={[
                  800, 950, 1070, 1190, 1300, 1420, 1510, 1660, 1790, 1900,
                  1990, 2100,
                ]}
              />
            </Col>
            <Col lg="6">
              <ComparisonChart
                graphTitle={"Plans Created Monthly"}
                labelNames={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
                dataSetList={testdata}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {currentUser.onboarded === false && 

<Success />
        }
    </React.Fragment>
  );
};
SavingsManagement.propTypes = {
  currentUser: PropTypes.object,

  
};

const mapStateToProps = (state) => {
  const { currentUser} = state.Login;
  console.log(state)
  return { currentUser };
};

export default connect(mapStateToProps, {
})(SavingsManagement);
