import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import axios from "axios";
import { connect } from 'react-redux';


import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  FormGroup,
  Container,
  Input,
  FormFeedback,
  Label,
  Spinner,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
// import { login } from "../../features/userSlice";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik, Formik, Form, Field } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginSuccess, loginUser, socialLogin } from "../../store/auth/login/actions";

// import images
import profile from "../../assets/images/navlogo1.png";
import profile1 from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";

//Import config
import { facebook, google } from "../../config";
import { setCurrentUser, getCurrentUser, setCurrentVault } from '../../helpers/Utils';
import { ReactComponent as EyeCloseLogo  } from '../../assets/images/logos/eye-close.svg';
import { ReactComponent as EyeOpenLogo  } from '../../assets/images/logos/eye-open.svg';



const authEndpoint = process.env.REACT_APP_AUTHENDPOINT;


const Login = ({loginUserAction,loginSuccessAction }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser)
  const [admin, setAdmin] = useState();
  console.log(user)

  const [loading, setLoading] = useState(false);
  const [loadpage, setLoadPage] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalAccess, setModalAccess] = useState(false);

  const [showPassword, setShowPassword] = useState(false);



  const { error } = useSelector(state => ({
    
    error: state.Login.error,
  }));
  console.log(error)



  const validateFormSchema = Yup.object({
    email: Yup.string().required("Please Enter Your Email"),
    password: Yup.string().required("Please Enter Your Password"),
  });
  const [email] = useState("");
  const [password] = useState("");

  const initialValues = {
    email,
    password,
  };

  const loginUsers = (values) => {
    setLoadPage(true);

    console.log(values);
    const fd = {
      email: values.email,
      password: values.password,
    };


      // loginUserAction(values,history)



    axios({
      url: `${authEndpoint}rest-auth/login/`,
      method: "post",
      data: fd,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setLoadPage(false);

        console.log(response);
        if (response.data && response.status === 200) {
          setAdmin(response.data);
          console.log("Success");
          console.log(response.data);

          const item = {
            id: response.data.user.id,
            email: response.data.user.email,
            photo: response.data.user.photo,
            category: response.data.user.category,
            compromised: response.data.user.compromised,
            token: response.data.token,
            is_agent: response.data.user.is_agent,
            phone_number: response.data.user.phone,
            first_name: response.data.user.first_name,
            last_name: response.data.user.last_name,
            business_type: response.data.user.business_type,
            is_bvn_verified: response.data.user.is_bvn_verified,
            moniker: response.data.user.moniker,
            is_email_verified: response.data.user.is_email_verified,
            is_kyc_submitted: response.data.user.is_kyc_submitted,
            is_kyc_verified: response.data.user.is_kyc_verified,
            phone_verified : response.data.user.phone_verified,
            referral_link : response.data.user.referral_link,

          };
    
    
          console.log(item);
         if(item.moniker === ("agent" || "agregator" ) ){

           setCurrentUser(item);
  
  
  
  
          loginSuccessAction(item);
             history.push("/dashboard")
        }else{

          // history.push("/permissionerror")
          setModalAccess(true)

         }

        }
      })
      .catch((error) => {
        setLoadPage(false);

        console.log(error)
        console.log(error.response)
 
        if(error && error.response && error.response.status === 500){
          setModalMessage("Network Error ! Please Try again")
          setModalFailed(true)

         }
          else if(error && error.response && error.response.data && error.response.data.non_field_errors){

          // console.log(error.response.data.non_field_errors[0]);
           setModalMessage(error.response.data.non_field_errors[0])
           setModalFailed(true)
          // setModalAccess(true)


        }



      });



    // if(loginUser.user){
    //   console.log("i am in")
    //     const item = {
    //       id: loginUser.user.id,
    //       email: loginUser.user.email,
    //       // photo: loginUser.user.photo,
    //       category: loginUser.user.category,
    //       compromised: loginUser.user.compromised,
    //       token: loginUser.token,
    //       is_agent: loginUser.user.is_agent,
    //       phone_number: loginUser.user.phone,
    //       first_name: loginUser.user.first_name,
    //       last_name: loginUser.user.last_name,
    //       business_type: loginUser.user.business_type,
    //     };
  
  
    //     console.log(item);
    //      setCurrentUser(item);
    //      yield put(loginSuccess(item))
    //      history.push("/dashboard")
  };





  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Herconomy - Agency</title>
      </MetaTags>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <div className="account-pages login  " >

        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row style={{backgroundColor:"#F4BB01"}}>
                    <Col xs={7}>
                      <div className="text-dark p-4">
                        <h5 className="text-dark">Welcome Back !</h5>
                        <p>Sign in to continue Herconomy Agency</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile1} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={profile}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <Formik
                    validationSchema={validateFormSchema}
                    initialValues={initialValues}
                    onSubmit={loginUsers}
                  >
                    {({ errors, touched, setFieldValue }) => (
                      <Form autoComplete="off">
                        <div className="p-2">
                          <FormGroup>
                            <Label className="form-label">Email</Label>
                            <Field
                              name="email"
                              className="form-control input-login"
                              placeholder="Enter email"
                              type="email"
                              autoComplete="off"
                              // value={validation.values.email || ""}
                              // invalid={
                              //   touched.email &&
                              //   errors.email
                              //     ? true
                              //     : false
                              // }
                            />
                            {errors.email && touched.email && (
                              <div className="invalid-feedback d-block">
                                <p>{errors.email}</p>
                              </div>
                            )}
                          </FormGroup>
                        </div>
                        <div className="p-2">
                          <FormGroup>
                            <Label className="form-label">Password</Label>
                            <Field
                              name="password"
                              className="form-control input-login"
                              placeholder="Enter password"
                              type={showPassword ? 'text' : 'password'}
                              autoComplete="off"
                              // value={validation.values.email || ""}
                              // invalid={
                              //   touched.password &&
                              //   errors.password
                              //     ? true
                              //     : false
                              // }
                            />
                              {showPassword ? (
          <EyeOpenLogo color="#000" className="passwordicon" onClick={() => setShowPassword(false)} />
        ) : (
          <EyeCloseLogo color="#000" className="passwordicon" onClick={() => setShowPassword(true)} />
        )}
                            {errors.password && touched.password && (
                              <div className="invalid-feedback d-block">
                                <p>{errors.password}</p>
                              </div>
                            )}
                          </FormGroup>
                        </div>
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-login  btn-block"
                            type="submit"
                            disabled={loadpage}

                          >
                            {loadpage ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Log in'
                      )}
                          </button>
                        </div>

                        <div className="mt-5 text-center">
                {/* <p>
                  Don&apos;t have an account ?{" "}
                  <Link to="/register" className="font-weight-medium  p-login">
                    {" "}
                    Register
                  </Link>{" "}
                </p> */}
               
              </div>
              <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> Forgot your
                          password?
                        </Link>
                      </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
              isOpen={modalFailed}
              toggle={() => setModalFailed(!modalFailed)}
              style={{
                marginTop: '20%',
                border: 'solid #f7bd00 1px',
                borderRadius: '10px',
              }}
            >
              <ModalBody>
                <Row>
                  <Card className="w-100">
                    <CardBody>
                      <div className="d-flex flex-column justify-content-between align-items-center">
                        <p className="h5 mb-2"> Login Error</p>
                     <p className='h6 mb-2'>  {modalMessage}  </p>

                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p />

                        <Button
                          color="warning"
                          className="text-dark"
                          onClick={() => setModalFailed(false)}
                        >
                          close{' '}
                        </Button>
                        <p />
                      </div>
                    </CardBody>
                  </Card>
                </Row>{' '}
              </ModalBody>
            </Modal>
            <Modal
              isOpen={modalAccess}
              toggle={() => setModalAccess(!modalAccess)}
              style={{
                marginTop: '20%',
                border: 'solid #f7bd00 1px',
                borderRadius: '10px',
              }}
            >
              <ModalBody>
                <Row>
                  <Card className="w-100">
                    <CardBody>
                      <div className="d-flex flex-column justify-content-between align-items-center">
                      <Alert color="warning" >
                      Access restricted.
              </Alert>
                        {/* <p className="h5 mb-2"> Access restricted.</p> */}
                     <p className='h6 ms-3' > You are currently unable to access this dashboard, 
                     please contact <span style={{color:"blue"}}><a href = "mailto: support@herconomy.com"> support@herconomy.com </a> </span>to enable your access   </p>

                      </div>
                      {/* <div className="d-flex justify-content-between align-items-center">
                        <p />

                        <Button
                          color="warning"
                          className="text-dark"
                          onClick={() => setModalFailed(false)}
                        >
                          close{' '}
                        </Button>
                        <p />
                      </div> */}
                    </CardBody>
                  </Card>
                </Row>{' '}
              </ModalBody>
            </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

// export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
  loginUserAction: PropTypes.func,
  loginSuccessAction: PropTypes.func,


  
};


const mapStateToProps = (state) => {
  const { loading, error,currentUser } = state;
  console.log(state.user)
    console.log(currentUser);
  return { loading, error };
};

export default connect(mapStateToProps, {
  loginUserAction: loginUser,
  loginSuccessAction:loginSuccess
})(Login);