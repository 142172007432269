import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from '../constants/defaultValues';
//  import jwt_decode from "jwt-decode";

import axios from 'axios';
 import ls from 'localstorage-slim';
 
 ls.config.encrypt = true;


export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = {
    email: null,
    first_name: null,
    id: null,
    last_name: null,
    compromised: null,
    photo: null,
    is_agent: null,
    token: null,
    phone_number: null,
    category: null,
    business_type: null,
    is_bvn_verified: null,
    is_email_verified: null,
    is_kyc_submitted: null,
    is_kyc_verified: null,
    phone_verified : null,

    

  };
  try {
    user =
      localStorage.getItem('gogo_current_user') != null
        ? JSON.parse(ls.get('gogo_current_user'))
        : {
          email: null,
          first_name: null,
          id: null,
          last_name: null,
          compromised: null,
          photo: null,
          is_agent: null,
          token: null,
          phone_number: null,
          category: null,
          business_type: null,
          is_bvn_verified: null,
          is_email_verified: null,
          is_kyc_submitted: null,
          is_kyc_verified: null,
          phone_verified : null,
          };
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = {
      email: null,
      first_name: null,
      id: null,
      last_name: null,
      compromised: null,
      photo: null,
      is_agent: null,
      token: null,
      phone_number: null,
      category: null,
      business_type: null,
      is_bvn_verified: null,
      is_email_verified: null,
      is_kyc_submitted: null,
      is_kyc_verified: null,
      phone_verified : null, 
    };
  }
  return user;
};

// export const getUserDetail =()=>{
// let getUser=null
//   try{
//     const getCurrent = getCurrentUser();
//     console.log(getCurrent)
//       getUser = jwt_decode(getCurrent)
//      console.log(getUser)
//    return getUser

//   }catch(error){
//   console.log("decode jwt error",error)
//   }

// return getUser;

// }

export const setCurrentUser = (user) => {
  try {
    if (user) {
      ls.set('gogo_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('gogo_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const setCurrentVault = (vault) => {
  try {
    if (vault) {
      ls.set('gogo_current_vault', JSON.stringify(vault));
    } else {
      localStorage.removeItem('gogo_current_vault');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentVault -> error', error);
  }
};

export const getCurrentVault = () => {
  let vault = null;
  const savingsAccount = {
    id: null,
    last_save: null,
    last_withdrawal: null,
    next_withdrawal: null,
    balance: 0,
    channel: null,
    save_interval: null,
    save_amount: 0,
    next_save: null,
    set_up: false,
    active: false,
    cards: [],
    last_deposit: null,
    transactions: [],
  };
  try {
    vault =
      localStorage.getItem('gogo_current_vault') != null
        ? JSON.parse(ls.get('gogo_current_vault'))
        : savingsAccount;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getCurrentvault -> error',
      error
    );
    vault = null;
  }
  return vault;
};

const base_ql_url = process.env.REACT_APP_BASE_QL_HTTP;
export const handleQuery = (fd, token, timer = true) => {
  console.log(timer);
  console.log('fd', fd);
  console.log('token', token);
  const source = axios.CancelToken.source();
  global.cancel = source.cancel;
  return new Promise((resolve, reject) => {
    axios
      .post(
        base_ql_url,
        {
          query: `${fd}`,
        },
        token
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : null,
        {
          cancelToken: source.token,
        }
      )
      .then((res) => {
        console.log('res handle query', res);
        if (res && res.data) {
          // const error = res.data.errors[0];
          // // logError(base_ql_url,fd,error)
          // console.log('error', error);
          // let error_msg = {
          //   msg: 'Network Error! Please try again',
          //   code: 500,
          // };
          // if (
          //   error &&
          //   error.extensions &&
          //   error.extensions.exception &&
          //   error.extensions.exception.data &&
          //   error.extensions.exception.data.data &&
          //   error.extensions.exception.data.data[0] &&
          //   error.extensions.exception.data.data[0].messages &&
          //   error.extensions.exception.data.data[0].messages[0]
          // ) {
          //   error_msg = {
          //     msg: error.extensions.exception.data.data[0].messages[0].message,
          //     code: error.extensions.exception.code,
          //   };
          // }
          return resolve(res.data);
        }
        return resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        if (axios.isCancel()) {
          return;
        }

        let error_msg = {
          msg: 'Network Error! Please try again',
          code: 500,
        };
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data[0] &&
          error.response.data.data[0].messages &&
          error.response.data.data[0].messages[0]
        ) {
          error_msg = {
            msg: error.response.data.data[0].messages[0].message,
            code: error.response.status,
          };
        }
        reject(error_msg);
      });
  });
};
