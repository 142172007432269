import React from 'react';

const FallBackUI = () => {
  return (
    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
      <svg
        width="127"
        height="117"
        viewBox="0 0 127 117"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M68.1778 12.3435C66.3469 9.15524 60.6531 9.15524 58.8222 12.3435L11.1972 95.2185C10.7685 95.9613 10.5559 96.7937 10.5803 97.6347C10.6046 98.4757 10.865 99.2965 11.3361 100.017C11.8072 100.738 12.4729 101.334 13.2683 101.747C14.0638 102.16 14.9618 102.377 15.875 102.375H111.125C112.038 102.377 112.935 102.161 113.73 101.748C114.525 101.335 115.19 100.739 115.661 100.019C116.131 99.2985 116.391 98.4781 116.415 97.6377C116.439 96.7973 116.226 95.9655 115.798 95.2234L68.1778 12.3435ZM68.7917 87.75H58.2083V78H68.7917V87.75ZM58.2083 68.25V43.875H68.7917L68.797 68.25H58.2083Z"
          fill="#FFC700"
        />
      </svg>
      <p className="text-dark h4 text-center my-5">Something went wrong</p>
      <p className="text-center mb-5">Please try again</p>
      <div className="text-center">
        <a href="/app/dashboards/home">
          <p
            className="ml-1 text-warning "
            style={{ textDecoration: 'underline' }}
          >
            Go to home
          </p>
        </a>
      </div>
    </div>
  );
};

export default FallBackUI;
