import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { ErrorBoundary } from 'react-error-boundary';
import FallBackUI from 'pages/Components/FallBackUI';


import store from "./store/store";
import store2 from "./store"

const store1Context = React.createContext();
const store2Context = React.createContext();

const app = (
  <ErrorBoundary
  FallbackComponent={FallBackUI}

  onError={(error, errorInfo) => {
    
    console.log({ error, errorInfo })
    if (error) { document.body.classList.add('error')} else {document.body.classList.remove('error')}
  
  }}
>
  <Provider store={store}>
    {/* <Provider store={store} context={store2Context}> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/* </Provider> */}
  </Provider>
  </ErrorBoundary>

);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
