import React, { Component, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Row, Col, Card, CardBody, CardTitle, Container, Modal, ModalBody, Button, Dropdown, Input, FormGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SummaryCard from "../Components/SummaryCard";
import MonthlyReg from "../Components/MonthlyReg";
import ComparisonChart from "../Components/ComparisonChart";
import LineChart from "../Components/LineChart";
import UserList from "../Components/UserList";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import fileDownload from 'js-file-download'
import { CSVLink } from 'react-csv';
import jsPDF from "jspdf"
import "jspdf-autotable"
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";



import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Success from "../Components/Success";
import Report from "pages/Components/Report";
// import { pageOptions } from "pages/Components/TableHead";
import { columns } from "./columnuserlist";



const generatePdf = () => {

  let doc = new jsPDF();
  console.log(doc)

  doc.text(20, 20, 'This is the first page title.')

  doc.setFont('helvetica')
  doc.text(20, 60, 'This is the content area.')
  doc.autoTable({
    theme: 'grid',
    startY: 20,
    head: [['S/N0', 'Name', 'Email', 'Phone No', 'Auto-Save', 'Frequency', 'salary']],
    body: data1,
  });
  doc.save('sample-file.pdf')
}

// const columns = [{
//   dataField: 'id',
//   text: 'S/No',
//   //   sort: true,
// }, {
//   dataField: 'name',
//   text: 'Full Name',
//   //   sort: true
// }, {
//   dataField: 'email',
//   text: 'Email',
//   //   sort: true
// }, {
//   dataField: 'phoneno',
//   text: 'Phone Number',
//   //   sort: true
// }
//   , {
//   dataField: 'easy save?',
//   text: 'Easy save?',
//   //   sort: true
// },
// {
//   dataField: 'frequency of savings',
//   text: 'Frequency of Savings',
//   //   sort: true
// }

// ];


const productData2 = [[1, "Miriam Dike", "tochi@herconomy.com", "08083844955", "yes", "Daily"], [1, "Miriam Dike", "tochi@herconomy.com", "08083844955", "yes", "Daily"]]


// Table Data
const productData = [
  { "id": 1, "name": "Miriam Dike", "email": "tochi@herconomy.com", "phoneno": "08083844955", "frequency of savings": "Daily", "easy save?": "yes", "salary": "$162,700" },

  { "id": 2, "name": "Miriam Dike", "email": "tochi@herconomy.com", "phoneno": "08083844955", "frequency of savings": "Daily", "easy save?": "yes", "salary": "$162,700" },

  { "id": 3, "name": "Miriam Dike", "email": "tochi@herconomy.com", "phoneno": "08083844955", "frequency of savings": "Weekly", "easy save?": "no", "salary": "$162,700" },

  { "id": 4, "name": "Miriam Dike", "email": "tochi@herconomy.com", "phoneno": "08083844955", "frequency of savings": "Daily", "easy save?": "no", "salary": "$162,700" },

  { "id": 5, "name": "Miriam Dike", "email": "tochi@herconomy.com", "phoneno": "08083844955", "frequency of savings": "Weekly", "easy save?": "no", "salary": "$162,700" },

  { "id": 6, "name": "Miriam Dike", "email": "tochi@herconomy.com", "phoneno": "08083844955", "frequency of savings": "Daily", "easy save?": "yes", "salary": "$162,700" },

  { "id": 7, "name": "Miriam Dike", "email": "tochi@herconomy.com", "phoneno": "08083844955", "frequency of savings": "Monthly", "easy save?": "yes", "salary": "$162,700" },

  { "id": 8, "name": "Miriam Dike", "email": "tochi@herconomy.com", "phoneno": "08083844955", "frequency of savings": "Monthly", "easy save?": "yes", "salary": "$162,700" },

  { "id": 9, "name": "Cara Stevens", "email": "Sales Assistant", "phoneno": "New York", "frequency of savings": "46", "easy save?": "yes", "salary": "$145,600" },

  //   { "id": 30, "name": "Jennifer Acosta", "position": "Junior Javascript Devel,per", "office": "Edinburgh", "age": "43", "startdate": "2013/02/01", "salary": "$75,650" }

];

const data1 = productData.map((item) => {

  return [item.id, item.name, item.position, item.office, item.age, item.startdate, item.salary]

}
); //and map all the data you require.



const defaultSorted = [{
  dataField: 'id',
  order: 'asc'
}];

const pageOptions = {
  sizePerPage: 10,
  totalSize: productData.length, // replace later with size(customers),
  custom: true,
}

// Custom Pagination Toggle
const sizePerPageList = [
  { text: '5', value: 5 },
  { text: '10', value: 10 },
  { text: '15', value: 15 },
  { text: '20', value: 20 },
  { text: '25', value: 25 },
  { text: 'All', value: (productData).length }];


// Select All Button operation
const selectRow = {
  mode: 'checkbox'
}

const { SearchBar } = Search;

const UserManagement = ({ currentUser }) => {
  const [modalError, setModalError] = useState(true);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category: '',

      // username: '',
      // password: '',
    },
    validationSchema: Yup.object({

      category: Yup.string().required("Please Enter Your Catergory"),
      // invalidCheck:Yup.boolean().oneOf([true],'Message'),
    }),
    onSubmit: (values) => {

      console.log(values)
      console.log(history)
    }
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>user management | herconomy - Agency</title>
        </MetaTags>
        <div className="container-fluid">
          <div className="font-size-24">
            User List
          </div>
          {/* <Download generatePdf={generatePdf} productData={productData} /> */}

         <Report productData={productData} pageOptions={pageOptions} columns={columns} />
        </div>
      </div>
      {/* {currentUser.onboarded === false && 

 <Success />
         } */}
    </React.Fragment>
  );
};

UserManagement.propTypes = {
  currentUser: PropTypes.object,


};

const mapStateToProps = (state) => {
  const { currentUser } = state.Login;
  console.log(state)
  return { currentUser };
};

export default connect(mapStateToProps, {
})(UserManagement);
