import React from "react";
import { Container, Row, Col ,Input} from "reactstrap";

//Import Components
import FeatureBox from "./feature-box";

//Import images
import feature1 from "../../../../assets/images/crypto/features-img/img-1.png";
import feature2 from "../../../../assets/images/crypto/features-img/img-2.png";
import benefits from "../../../../assets/images/users/becomeagent.png"
import benefits2 from "../../../../assets/images/users/becomeagent1.png"

import { BsCheckSquare } from "react-icons/bs";


const Features = () => {
  const features1 = [
    { id: 1, desc: "Donec pede justo vel aliquet" },
    { id: 2, desc: "Aenean et nisl sagittis" },
  ];
  const features2 = [
    { id: 1, desc: "Donec pede justo vel aliquet" },
    { id: 2, desc: "Aenean et nisl sagittis" },
  ];

  return (
    <React.Fragment>
       <section className="section hero-section " id="features">
        {/* <div className="bg-overlay bg-primary" /> */}
        <Container>
          <Row className="becomeagent">
           
            <p className="font-weight-bold text-dark agent-text">Why you should be an Agent?</p>
            {/* <Col lg="10"> */}
            <img
            className="overflow-hidden  agentimg"
            src={ benefits}

            alt="Header Avatar"
          
          />
            {/* </Col> */}
            
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
