import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
import axios from 'axios';
import { setCurrentUser, getCurrentUser, setCurrentVault } from '../../../helpers/Utils';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()
const authEndpoint = process.env.REACT_APP_AUTHENDPOINT;

console.log(authEndpoint)

const loginWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  // await auth
  //   .signInWithEmailAndPassword(email, password)
  axios
    // .post(`https://admin-herconomy.herokuapp.com/api/v1/rest-auth/login/`, {
      .post(`${authEndpoint}rest-auth/login/`, {
      email,
      password,
    })
    .then((response) => {
   console.log(response.data)
     return response.data
    }
    )
    .catch((error) => {
     console.log(error.response)
     return error.response
    }
    );

function* loginUser({ payload: { user,history} }) {
console.log(user)
console.log(history)
  // const { email, password } = payload.user;
  // const { history } = payload;
  try {
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const loginUser = yield call(loginWithEmailPasswordAsync, user.email,
 user.password
      )

      console.log(loginUser)
      if(loginUser.user){
        console.log("i am in")
          const item = {
            id: loginUser.user.id,
            email: loginUser.user.email,
            photo: loginUser.user.photo,
            category: loginUser.user.category,
            compromised: loginUser.user.compromised,
            token: loginUser.token,
            is_agent: loginUser.user.is_agent,
            phone_number: loginUser.user.phone,
            first_name: loginUser.user.first_name,
            last_name: loginUser.user.last_name,
            business_type: loginUser.user.business_type,
          };
    
    
          console.log(item);
           setCurrentUser(item);
           yield put(loginSuccess(item))
           history.push("/dashboard")


    } else{
      yield put(apiError(loginUser.data.non_field_errors[0]))

    }
  } catch (error) {
    console.log(error.data.non_field_errors[0])
    yield put(apiError(error.data.non_field_errors[0]))
  }
}




function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    console.log("i click on logout")
     setCurrentUser(null);

    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
