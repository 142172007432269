const SidebarItemList = [
    {id: 1, linkurl: "/dashboard", iconclass: "bx bx-home-circle", linkname: "Dashboard"},
    // {id: 3, linkurl: "/savingsmanagement", iconclass: "bx bxs-bank", linkname: "Savings"},
    // {id: 2, linkurl: "/transfers", iconclass: "bx bxs-user-plus", linkname: "Transfers"},
    //  {id: 5, linkurl: "/#", iconclass: "bx bxs-bell", linkname: "Withdrawals"},
    {id: 2, linkurl: "/commission", iconclass: "bx bxs-network-chart", linkname: "Commission"},
    //  {id: 7, linkurl: "/#", iconclass: "bx bx-message-dots", linkname: "Refferal link"},
    // {id: 3, linkurl: "/usermanagement", iconclass: "bx bx-user", linkname: "User Management"},
    // {id: 4, linkurl: "/agentmanagement", iconclass: "bx bxs-user-plus", linkname: "Agent Management"},


    // {id: 5, linkurl: "/reports", iconclass: "bx bxs-bell", linkname: "Reports"},
    //  {id: 6, linkurl: "/contact", iconclass: "bx bx-wrench", linkname: "Contact us"},
//  {id: 7, linkurl: "/transfers", iconclass: "bx bxs-user-plus", linkname: "Transfers"},

    // {id: 7, linkurl: "/#", iconclass: "bx bxs-user-plus", linkname: "Support"},

    // {id: 6, linkurl: "/#", iconclass: "bx bxs-user-plus", linkname: "Refer and Earn"},
    // {id: 7, linkurl: "/#", iconclass: "bx bxs-bell", linkname: "Push Notification"},
    // {id: 8, linkurl: "/#", iconclass: "bx bx-message-dots", linkname: "In-App Survey"},
]


export default SidebarItemList