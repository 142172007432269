import React from "react"
import { Redirect } from "react-router-dom"

// Profile
// import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register"
// import ForgetPwd from "../pages/Authentication/ForgetPassword"
// import ResetPwd from "../pages/Authentication/resetPassword"


import LandingPage from "../pages/Authentication/CryptoIcoLanding/index"



// Dashboard
import Dashboard from "../pages/Dashboard/index"

import UserManagement from "../pages/User_Management/index"
import UserList from "../pages/User_Management/userlist"
import UserSavingsList from "../pages/User_Management/usersavingslist"

// //Agent Management
// import AgentSubList from "../pages/Agent_Management/subagentList"
// import AgentSubActivityList from "../pages/Agent_Management/subagentActivityList"

// // Savings
import SavingsManagement from "../pages/Savings/index"



// // Commision
import Commission from "../pages/Commission/index"
import CommissionInterest from "../pages/Commission/commission-interest"


// //transfers
 import Transfers from "../pages/Transfers/index"


//   //compliance
//   import Compliance from "../pages/Components/Compliance"
//   //  import Compliance from "../pages/Commission/index"
  
  
//    //reports
//    import Reports from "../pages/Reports/index"


//    import TransfersReport  from "../pages/Reports/transfersReport"

   

// import Contact from "../pages/Contact/index"
// import BankAccountSettings from "../pages/Contact/bankaccount"
// import EditBankAccount from "../pages/Contact/editBankAccount"
// import FAQ from "../pages/Contact/frequencyasked"
//  import SecuritySettings from "../pages/Contact/securitysettings"
// import { Password } from "@mui/icons-material"
 import permissionerror from "pages/Authentication/PermissionError"



const authProtectedRoutes = [
  // Dashboard
  { path: "/dashboard", component: Dashboard },


  { path: "/usermanagement", component: UserManagement},


  { path: "/savingsmanagement", component: SavingsManagement},
  {path:"/userlist", component: UserList},

{path:"/usersavingslist", component: UserSavingsList},


// //   Agent Management
// { path: "/agentsmanagement", component: SavingsManagement},
// {path:"/agentsublist", component: AgentSubList},

// {path:"/agentactivitysublist", component: AgentSubActivityList},


//   // //profile
//   { path: "/profile", component: UserProfile },

//   //commission
  {path:"/commission", component: Commission},
//   {path:"/commission-interest", component: CommissionInterest},


//   //tranfers
  {path:"/transfers", component: Transfers},

// //Compliance
// {path:"/compliance", component: Compliance},


// //Reports
// {path:"/reports", component: Reports},

// {path:"/reports/transfers", component: TransfersReport},




//   {path:"/contact", component: Contact},
//   {path:"/account/bankaccountsettings", component: BankAccountSettings},
//   {path:"/editBankAccount", component: EditBankAccount},

//   {path:"/faq", component: FAQ},
//   {path:"/securitysettings", component: SecuritySettings},



  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/reset-password", component: ResetPwd },

  
  // { path: "/register", component: Register },
    { path: "/permissionerror", component: permissionerror },

  { path: "/landing", component: LandingPage },

]

export { publicRoutes, authProtectedRoutes }
