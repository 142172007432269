import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Badge,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from "reactstrap";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Numeral from 'react-numeral';
import { Link } from 'react-router-dom';



import classnames from "classnames";
import { useSelector, connect } from "react-redux";
import PropTypes from "prop-types";
import fileDownload from 'js-file-download'



//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import Success from "../Components/Success";
import axios from "axios";
import SummaryCard from "pages/Components/SummaryCard";
import { ReactComponent as InfoIcon } from '../../assets/images/logos/info.svg';
import { ReactComponent as InterestIcon } from '../../assets/images/logos/percent.svg';
import { ReactComponent as BulbIcon } from '../../assets/images/logos/bulb.svg';
import { ReactComponent as SavingsDayIcon } from '../../assets/images/logos/vault-savings-day-logo.svg';


const authEndpoint = process.env.REACT_APP_AUTHENDPOINT;





const CommissionInterest = ({ currentUser }) => {

  const [activeTab, setActiveTab] = useState("1");
  const [isMenu, setIsMenu] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [modalBasic, setModalBasic] = useState(false);
  const [commissionInterest, setCommissionInterest] = useState(5000);
   const [loading, setLoading] = useState(false);
   const [showToolTip, setShowToolTip] = useState(false);

  const getWalletBalance = () => {

    axios({
      url: `${authEndpoint}transaction/getTransactionWallet/`,
      method: "get",
      headers: {
        Authorization: `JWT ${currentUser.token}`,
      },
    })
      .then((response) => {

        console.log(response)

        if (response && response.status === 200) {

          setWalletBalance(response && response.data && response.data.balance)
        }


      })
      .catch((error) => {
        // setLoadVerify(false);
        console.log(error)
        console.log(error.response)
        // setTransactionMessage(error && error.response && error.response.data && error.response.data.message)
        // setTransaction(true)
      });

  }

  useEffect(() => {

    getWalletBalance();

  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="font-size-18">COMMISSION </div>

  <Row>
    <Col lg="12" >
        <Card
        
        style={{ border: 'solid #f7bd00 1px', borderRadius: '10px' }}
        className="overall-card mb-2">
            <CardBody>

            <div className="font-size-14 mb-2">Commision Earned</div>

            <Row className="mb-2">
                <Col lg="12" sm="12" md="12" color="warning" className="mb-5">
                  <Card
                    style={{
                      borderRadius: '10px',
                      backgroundColor: '#f7bd00',
                      boxShadow: 'none',
                      // backgroundImage:
                      //   'url("/assets/img/cards/Savings_background.png")',
                    }}
                    className="d-flex flex-column  justify-content-between align-items-center"
                  >
                    <CardBody>
                      {/* <NavLink to="/app/vaultwithdraw"> */}
                      <div className="d-flex flex-column align-items-center">
                        <p className="text-dark card-text h4 mb-2 font-weight-semibold para-head">
                          You have earned  <span>&#8358;</span> 5,000 for user Acquisition for  this
                          month
                        </p>
                        <p className="text-dark text-xlarge font-weight-bold h1  mb-2 medium-para">

                        </p>
                      </div>
                  
                    </CardBody>
                  </Card>
                </Col>
              </Row>



              <div className="d-flex justify-content-start align-items-center ms-3">
                <Link to="/commission">
                  <Button
                    href="#"
                    color="outline-warning"
                    className=" mr-3 mb-0 text-dark me-3 ms-3 "
                    // onClick={()=>{onContinue()}}
                  >
                    <p className="text-dark font-weight-semibold ms-2 h6"> Close</p>
                  </Button>
                </Link>

                {commissionInterest === 0 && (
                  <NavLink to="/breakdown">
                    <Button
                      href="#"
                      color="warning"
                      className="text-dark "

                      // onClick={()=>{onContinue()}}
                    >
                      <p className="text-dark font-weight-semibold h6">
                        Breakdown
                      </p>
                    </Button>
                  </NavLink>
                )}
                {commissionInterest !== 0 && (
                  <Button
                    href="#"
                    color="warning"
                    className=" mr-2  text-dark "
                    onClick={() => {
                      setModalBasic(true);
                    }}
                  >
                    {' '}
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <p className="text-dark font-weight-semibold h6">Claim</p>
                    )}{' '}
                  </Button>
                )}
              </div>

            </CardBody>
        </Card>
    </Col>
  </Row>
  <Modal
        isOpen={modalBasic}
        toggle={() => setModalBasic(!modalBasic)}
        style={{ borderRadius: '20px' }}
        className="modal-margin"
      >
        <ModalBody>
          <Row className="message-cont">
            {/* <img
              className=" mod-img mb-2"
              src="/assets/img/cards/claiminterest.png"
              alt="img"
            /> */}
            <p className="text-large font-weight-bold head-para mb-4">
              Well done!
            </p>
            <p className="h5 text-dark para-center mb-3 ml-3 mr-3 ">
              You have earned <span>&#8358;</span>
              {commissionInterest ? (
                <Numeral value={commissionInterest} format="0,0.00" />
              ) : (
                <Numeral value="35017" format="0,0.00" />
              )}{' '}
              in interest on your User Referrals.
            </p>

            <div className="d-flex justify-content-between align-items-center mb-3">
              <p />
              <div>
                <Button
                  color="warning"
                  className="text-dark font-weight-bold "
                   onClick={() => setModalBasic(false)}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    'Claim'
                  )}
                </Button>
              </div>
              <p />
            </div>
{/* 
            <p className="ml-3 mr-3 para-small-center">
              {' '}
              N.B. By clicking confirm you are agreeing to use your money wisely
              and not engage in any fraudulent activity.
            </p> */}
          </Row>
        </ModalBody>
      </Modal> 
        </Container>
      </div>

    </React.Fragment>
  );
};

CommissionInterest.propTypes = {
  currentUser: PropTypes.object,


};

const mapStateToProps = (state) => {
  const { currentUser } = state.Login;
  console.log(state)
  return { currentUser };
};

export default connect(mapStateToProps, {
})(CommissionInterest);