import React, { useState } from "react"
import { Container, Row, Col, CardBody,Card, Button } from "reactstrap"
import createaccount from "../../../../assets/images/users/createaccount.png"
import startearning from "../../../../assets/images/users/startearning.png"
import setupaccount from "../../../../assets/images/users/setupaccount.png"


const GetStarted = () => {
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)

  return (
    <React.Fragment>
      <section className="section " id="getstarted" style={{backgroundColor:"#FFF0CACC"}}>
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                {/* <div className="small-title">Timeline</div> */}
                <h4>GET STARTED</h4>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg="12">
              <div className="hori-timeline">
                <div
                  className="owl-carousel owl-theme  navs-carousel events"
                  id="timeline-carousel"
                >
                  {step1 ? (
                    <>
                      <Row>
                        <Col md="4" lg='4' sm='4'>
                          <Card className="getStartedCard">
                            <CardBody className="landing bg-white">

                            
                          
                           <div className="me-1 mb-3 getstarteddiv" style={{backgroundColor :"#FFE3E7"}}>
                            <img src={createaccount} className="getstartedicon"></img>
                           </div>

                            <h5 className="mb-2 font-weight-bold getstarted-title">Create an Account</h5>
                            <div className="  mb-1 getstarted-text">
                                 Create a free herconomy agency account
                                </div>
                            </CardBody>
                          </Card>
                          
                        </Col>
                        <Col md='4' lg='4' sm='4'>
                        <Card className="getStartedCard">
                            <CardBody className="landing  bg-white ">
                            <div className="me-1 mb-3 getstarteddiv" style={{backgroundColor :"#DAE8F4"}}>
                            <img src={setupaccount} className="getstartedicon"></img>
                           </div>

                            <h5 className="mb-2 font-weight-bold getstarted-title">Setup your Account</h5>
                            <div className="  mb-1 getstarted-text">
                            Complete your registration process and upload all required documents 
                                </div>
</CardBody>
                          </Card>
                          </Col>
                          <Col md="4" lg='4' sm='4'>
                        <Card className="getStartedCard">
                            <CardBody className=" landing  bg-white">
                            <div className="me-1 mb-3 getstarteddiv" style={{backgroundColor :"#EFF3BC"}}>
                            <img src={startearning} className="getstartedicon"></img>
                           </div>

                            <h5 className="mb-2 font-weight-bold getstarted-title">Start Earning</h5>
                            <div className="  mb-1 getstarted-text">
                            Share your referral link and start providing access to financial services for women    
                                </div>
                             
                          
                            </CardBody>

                          </Card>
                        </Col>
                        <div className="d-flex justify-content-center align-items-center">
                             <a
 href ="http://herconomy.com"
  target="_blank"
 rel="noreferrer noopener"
> <Button className="btn btn-dark font-weight-semibold font-size-16 text-white me-1 " style={{borderRadius:"5px"}}>
                                  Back to Main Website
                                 </Button>
                                 </a>
                             </div>
                      </Row>
                    </>
                  ) : null}

                 

                  {/* <div className="owl-nav" style={{ textAlign: "center" }}>
                    <button
                      type="button"
                      onClick={() => {
                        setStep1(true)
                        setStep2(false)
                      }}
                      className="border-0"
                      disabled={step1}
                    >
                      <i className="mdi mdi-chevron-left"/>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setStep1(false)
                        setStep2(true)
                      }}
                      className="border-0"
                      disabled={step2}
                    >
                      <i className="mdi mdi-chevron-right"/>
                    </button>
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default GetStarted
